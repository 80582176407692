import { color } from "chart.js/helpers";

// tableStyles.js
export const thStyle = {
    padding: '3px !important',
    border: '1px solid #ccc !important',
    textAlign: 'center !important',
    backgroundColor: '#333 !important', // Dark background
    color: '#fff !important', // White text
    fontSize: '8px !important',
    fontWeight: 'normal',
  };
  
  
  export const tdStyle = {
    padding: '5px',
    border: '1px solid #ccc',
    textAlign: 'center',
  };
  
  export const inputStyle = {
    width: '100%',
    padding: '3px',
    margin: '0',
    boxSizing: 'border-box',
    borderRadius: '4px',
    border: '1px solid #ccc',
  };

  
  