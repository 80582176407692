// FieldConfig.js

export class FieldConfig {
  constructor(key, label, placeholder = "", priority = 1, type = 'input', visibility = 'Readonly', IsVisible = true, place = '', PagePermission = 'disable') {
    this.key = key;
    this.label = label;
    this.placeholder = placeholder;
    this.priority = priority;
    this.type = type;
    this.visibility = visibility;
    this.IsVisible = IsVisible;
    this.PagePermission = PagePermission;
    this.place = place;

  }
  // Method to update visibility based on API response
  setVisibility(isVisible) {
    this.isVisible = isVisible;
  }
}

export const PurchaseEntryFields = [
  new FieldConfig("customerName", "Firm Name", "", 1, "inputselect", "ReadWrite", true, "top"),
  new FieldConfig("addsupplier", "Add Supplier", "", 1, "Button", "ReadOnly", true, "top"),
  new FieldConfig("toplotnumber", "Lot Number", "", 1, "text", "ReadOnly", true, "top"),
  new FieldConfig("findgole", "Fine Gold", "", 1, "text", "ReadOnly", true, "top"),
  new FieldConfig("findsilver", "Fine Silver", "", 1, "text", "ReadOnly", true, "top"),
  new FieldConfig("advanceamount", "Advance Amount", "", 1, "text", "ReadOnly", true, "top"),
  new FieldConfig("balanceamount", "Balance Amount", "", 1, "text", "ReadOnly", true, "top"),



  new FieldConfig("StockKeepingUnit", "Enter Sku", "", 1, "inputselect", "ReadWrite", true, "middle"),
  new FieldConfig("CategoryId", "Category", "", 1, "dropdown", "ReadOnly", true, "middle"),
  new FieldConfig("ProductId", "Product", "", 1, "dropdown", "ReadOnly", true, "middle"),
  new FieldConfig("PurityId", "Purity", "", 1, "dropdown", "ReadOnly", true, "middle"),
  new FieldConfig("DesignId", "Design", "", 1, "dropdown", "ReadOnly", true, "middle"),

  // new FieldConfig("CategoryName", "CategoryName", "", 1, "dropdown", "ReadOnly", false, "middle"),
  // new FieldConfig("ProductName", "ProductName", "", 1, "dropdown", "ReadOnly", false, "middle"),
  // new FieldConfig("PurityName", "PurityName", "", 1, "dropdown", "ReadOnly", false, "middle"),
  // new FieldConfig("DesignName", "DesignName", "", 1, "dropdown", "ReadOnly", false, "middle"),



  new FieldConfig("TotalWt", "Total wt", "", 1, "number", "ReadOnly", true, "middle"),
  new FieldConfig("PackingWt", "Packing wt", "", 1, "number", "ReadOnly", true, "middle"),
  new FieldConfig("GrossWt", "Gross wt", "", 1, "number", "ReadOnly", true, "middle"),
  new FieldConfig("StoneWt", "Stone Wt", "", 1, "number", "ReadOnly", true, "middle"),
  new FieldConfig("ClipWeight", "Clip Wt", "", 1, "number", "ReadOnly", true, "middle"),
  new FieldConfig("ClipQuantity", "Clip Qty", "", 1, "number", "ReadOnly", true, "middle"),
  new FieldConfig("NetWt", "Net wt", "", 1, "number", "ReadOnly", true, "middle"),
  new FieldConfig("FinePercent", "Fine%", "", 1, "number", "ReadOnly", true, "middle"),
  new FieldConfig("WastagePercent", "Wastage%", "", 1, "number", "ReadOnly", true, "middle"),
  new FieldConfig("Quantity", "Qty", "", 1, "number", "ReadOnly", true, "middle"),
  new FieldConfig("StonePieces", "Stone Pcs", "", 1, "number", "ReadOnly", true, "middle"),
  new FieldConfig("MakingPerGram", "Making/GRM", "", 1, "number", "ReadOnly", true, "middle"),
  new FieldConfig("MakingFixedWastage", "Fixed Wastage", "", 1, "number", "ReadOnly", true, "middle"),
  new FieldConfig("MakingFixedAmt", "Fixed Amt", "", 1, "number", "ReadOnly", true, "middle"),
  new FieldConfig("MakingPercentage", "Making%", "", 1, "number", "ReadOnly", true, "middle"),
  new FieldConfig("MetalRate", "Rate/10Gm", "", 1, "number", "ReadOnly", true, "middle"),
  new FieldConfig("StoneAmount", "Stone Amt", "", 1, "number", "ReadOnly", true, "middle"),
  new FieldConfig("HallmarkAmt", "Hallmark", "", 1, "number", "ReadOnly", true, "middle"),
  new FieldConfig("TagWeight", "Tag Wt", "", 1, "number", "ReadOnly", true, "middle"),
  new FieldConfig("FindingWeight", "Finding Wt", "", 1, "number", "ReadOnly", true, "middle"),
  new FieldConfig("LanyardWeight", "Lanyard wt", "", 1, "number", "ReadOnly", true, "middle"),
  new FieldConfig("ItemAmt", "Item Amt", "", 1, "number", "ReadOnly", true, "middle"),
  new FieldConfig("MakingAmt", "Making Amt", "", 1, "number", "ReadOnly", true, "middle"),
  new FieldConfig("TotalItemAmt", "Total Item Amt", "", 1, "number", "ReadOnly", true, "middle"),
  new FieldConfig("FineWt", "Fine Wt", "", 1, "number", "ReadOnly", true, "middle"),
  new FieldConfig("FineWastageWt", "FineWastage", "", 1, "number", "ReadOnly", true, "middle"),
  new FieldConfig("WastageWt", "Wastage wt", "", 1, "number", "ReadOnly", true, "middle"),
  new FieldConfig("Testing", "Testing", "", 1, "number", "ReadOnly", true, "middle"),
  new FieldConfig("AddToUnlabelled", "Add to unlabel", "", 1, "checkbox", "ReadOnly", true, "middle"),

  new FieldConfig("DiamondName", "Diamond Name", "", 1, "text", "ReadOnly", false, "middle"),
  new FieldConfig("DiamondAmount", "Diamond Amount", "", 1, "number", "ReadOnly", false, "middle"),
  new FieldConfig("DiamondPieces", "Diamond Pcs", "", 1, "number", "ReadOnly", false, "middle"),
  new FieldConfig("DiamondWeight", "Diamond wt", "", 1, "number", "ReadOnly", false, "middle"),
  new FieldConfig("DiamondRate", "Diamond Rate", "", 1, "number", "ReadOnly", false, "middle"),
  new FieldConfig("DiamondSize", "Diamond Size", "", 1, "number", "ReadOnly", false, "middle"),
  new FieldConfig("DiamondPurchaseRate", "D Purchase Rate", "", 1, "number", "ReadOnly", false, "middle"),
  new FieldConfig("DiamondSellRate", "D Sell Rate", "", 1, "number", "ReadOnly", false, "middle"),
  new FieldConfig("DiamondClarity", "D Clarity", "", 1, "text", "ReadOnly", false, "middle"),
  new FieldConfig("DiamondColour", "D Colour", "", 1, "text", "ReadOnly", false, "middle"),
  new FieldConfig("DiamondShape", "D Shape", "", 1, "text", "ReadOnly", false, "middle"),
  new FieldConfig("DiamondCut", "D Cut", "", 1, "text", "ReadOnly", false, "middle"),
  new FieldConfig("DiamondSettingType", "D SettingType", "", 1, "text", "ReadOnly", false, "middle"),
  new FieldConfig("DiamondPurchaseAmount", "D Purchase Amt", "", 1, "number", "ReadOnly", false, "middle"),
  new FieldConfig("DiamondSellAmount", "D Sell Amt", "", 1, "number", "ReadOnly", false, "middle"),
  new FieldConfig("DiamondDescription", "D Description", "", 1, "text", "ReadOnly", false, "middle"),
  new FieldConfig("StoneButton", "Stones", "", 1, "popupbutton", "ReadOnly", false, "middle"),
  new FieldConfig("DiamondButton", "Diamonds", "", 1, "popupbutton", "ReadOnly", false, "middle"),
];



