import React, { useState } from 'react';

import { TableContainer } from "@mui/material";
// import { thStyle, tdStyle, inputStyle } from '../../../support/addstock/tableStyles';
import { thStyle, tdStyle, inputStyle } from "../addstock/tableStyles";
import {  Button, Typography, TextField } from '@mui/material';



export default function Customertable({ columns, allCustomersData, handlenew, title }) {

    const [searchTerm, setSearchTerm] = useState('');

    // Filtered data based on the search term
    const filteredData = allCustomersData.filter((customer) =>
        columns.some((column) =>
            customer[column.key]?.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
    );

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    return (
        <div>
            {/* <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '16px' }}>
                <Typography variant="h6" style={{ margin: '0' }}>
                    {title}
                </Typography>
                <Button variant="contained" color="primary" onClick={handlenew}>
                    New
                </Button>
            </div> */}
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '16px' }}>
                <Typography variant="h6" style={{ margin: '0' }}>
                    {title}
                </Typography>
                <TextField
                    variant="outlined"
                    size="small"
                    placeholder="Search..."
                    value={searchTerm}
                    onChange={handleSearchChange}
                    style={{ marginRight: '10px', maxWidth: '200px' }}
                />
                <Button variant="contained" color="primary" onClick={handlenew}>
                    New
                </Button>
            </div>
            <TableContainer sx={{ borderSpacing: '0', borderCollapse: 'collapse' }}>
                <table size="small" style={{ borderRadius: '4px', borderCollapse: 'collapse', borderSpacing: '0' }}>
                    <thead style={{ backgroundColor: '#627383', color: '#FFFFFF' }}>
                        <tr>
                            {columns.map((column) => (
                                <th key={column.key} style={{
                                    padding: '3px',
                                    border: '1px solid #ccc',
                                    textAlign: 'center',
                                    backgroundColor: '#627383',
                                    color: '#fff',
                                    fontSize: '12px',
                                    fontWeight: 'normal',
                                }}>
                                    {column.label}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {filteredData.map((product, idx) => (
                            <tr key={product.Id} style={{ borderBottom: '1px solid #ccc' }}>
                                {columns.map((column) => (
                                    <td key={column.key} style={tdStyle}>
                                        {column.type === "read" ? (
                                            <span style={{ fontSize: '12px' }}>
                                                {column.calculate ? column.calculate(product) : product[column.key]}
                                            </span>
                                        ) : null}
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </TableContainer>
        </div>

    )

}