export const toPlainFields = (fields) =>
    fields.map(field => ({
        key: field.key,
        label: field.label,
        placeholder: field.placeholder,
        priority: field.priority,
        type: field.type,
        visibility: field.visibility,
        IsVisible: field.IsVisible,
        place: field.place
    }));