import React from "react";
import AdminHeading from "../Heading/AdminHeading";
import AdminBreadCrump from "../Heading/AdminBreadCrump";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
    a125,
    a128,
    a131,
    a178,
    a18,
    a181,
    a20,
    a33,
    a41,
    a47,
} from "../../../Api/RootApiPath";
import { useState } from "react";
import { InfinitySpin, MagnifyingGlass } from "react-loader-spinner";
import jsPDF from "jspdf";
import QRCode from "qrcode";
import { BsHandbag } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { AiOutlineEdit } from "react-icons/ai";
import "../../PagesStyles/AdminEcommerce.css";
import { GenerateLabel } from "../../../Other Functions/GenerateLabel";
import { Grid } from "@mui/material";
import { thStyle, tdStyle, inputStyle } from '../../../support/addstock/tableStyles';
import { TableContainer } from "@mui/material";
import GetApiService from "../../../Api/getapiService";
import { useAdminData } from "../AdminSettings/useAdminData.jsx";
import { ClipLoader } from "react-spinners";
import ErrorModal from "../../../Other Functions/popup";





export default function AdminInventory() {
    const [allProducts, setAllProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [selectAll, setSelectAll] = useState(false);

    const [labelCode, setLabelCode] = useState("");
    const [barCode, setBarCode] = useState("");
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [categoryName, setCategoryName] = useState("");
    const [allCategories, setAllCategories] = useState([]);
    const [productName, setProductName] = useState("");
    const [allProductTypes, setAllProductTypes] = useState([]);
    const [collectionName, setCollectionName] = useState("");
    const [allCollectionTypes, setAllCollectionTypes] = useState([]);
    const [checkedProducts, setCheckedProducts] = useState([]);
    const [selectedItemCodes, setSelectedItemCodes] = useState([]);
    const [deleteSelected, setDeleteSelected] = useState(false);
    const [deleteSelectedButton, setDeleteSelectedButton] = useState(false);
    const [selectBranch, setSelectBranch] = useState("");
    const productsPerPage = 100;



    const columns = [
        { key: 'deleteItem', label: 'Delete Item', type: 'checkbox', priority: 1 },
        { key: 'serialNumber', label: 'S.No', type: 'read', priority: 2 },
        { key: 'DesignName', label: 'Design Name', type: 'read', priority: 3 },
        { key: 'SKU', label: 'SKU', type: 'read', priority: 4 },
        { key: 'GrossWt', label: 'Gross Wt', type: 'read', priority: 5 },
        { key: 'TotalStoneWeight', label: 'Stone WT', type: 'read', priority: 6 },
        { key: 'ClipWeight', label: 'Clip WT', type: 'read', priority: 7 },
        { key: 'NetWt', label: 'Net Wt', type: 'read', priority: 8 },
        { key: 'TagWeight', label: 'Tag Wt', type: 'read', priority: 9 },
        { key: 'LanyardWeight', label: 'Lanyard Wt', type: 'read', priority: 10 },
        {
            key: 'totalWeight', label: 'Total Wt', type: 'read', priority: 11, calculate: (x) => (
                parseFloat(x.GrossWt) + parseFloat(x.TagWeight) + parseFloat(x.LanyardWeight)
            )
        },
        {
            key: 'stoneAmount', label: 'Stone Amt', type: 'read', priority: 12, calculate: (x) => (
                x.Stones && x.Stones.length > 0
                    ? x.Stones.reduce((a, b) => a + (parseFloat(b.StoneAmount) || 0), 0).toFixed(3)
                    : (0.0).toFixed(3)
            )
        },
        { key: 'HallmarkAmount', label: 'Hallmark Amt', type: 'read', priority: 13 },
        { key: 'MRP', label: 'MRP', type: 'read', priority: 14 },
        { key: 'ItemCode', label: 'Item Code', type: 'read', priority: 15 },
        { key: 'LotNumber', label: 'Inward No', type: 'read', priority: 16 },
        { key: 'RFIDCode', label: 'RFID Code', type: 'read', priority: 17 },
        { key: 'BranchName', label: 'Branch', type: 'read', priority: 18 },
        { key: 'TIDNumber', label: 'Tid', type: 'read', priority: 19 },
        { key: 'select', label: 'Select', type: 'checkbox', priority: 20 }
    ];

    const [errorMessage, setErrorMessage] = useState(null);
    const [showModal, setShowModal] = useState(false); // Modal visibility state

    const sortedColumns = columns.sort((a, b) => a.priority - b.priority);


    const navigate = useNavigate();

    const allStates = useSelector((state) => state);
    const adminLoggedIn = allStates.reducer1;
    //   let Entryby_Staff_id = parseInt(adminLoggedIn);
    // const clientCode = adminLoggedIn.ClientCode;
    // const employeeCode = adminLoggedIn.EmployeeCode;
    // const labelFormat = parseInt(adminLoggedIn.LabelFormat);
    const labelFormat = parseInt(adminLoggedIn.Clients.LabelFormat);


    const {
        clientCode,
        CompanyId,
        CounterId,
        BranchId,
        EmployeId,
        employeeCode,
        rdPurchaseFormat,
      } = useAdminData();

    const apiService = new GetApiService(clientCode);

  const loadData = async () => {
    setLoading(true);
    try {
      const apiCalls = [
        
        apiService.fetchAllCategories(),
        apiService.fetchAllProductType(),
        apiService.fetchAllDesigns(),
        apiService.fetchAllLabelledStock(),

      ];

      const results = await Promise.allSettled(apiCalls);

      results.forEach((result, index) => {
        if (result.status === "fulfilled") {
          // Handle successful response
          switch (index) {
            case 0:
              
            setAllCategories(result.value);
              break;
            case 1:
                setAllProductTypes(result.value);
              break;
              case 2:
                setAllCollectionTypes(result.value);
              break;
            case 3:
                
                let data = result.value;
                console.log('check all data', data)
                let branchProducts = data;
                console.log(branchProducts, "branchProducts");
                // Add a serial number property to each product
                if (selectBranch === "Home" || selectBranch === "Branch 2") {
                    branchProducts = data.filter((x) => x.BranchName === selectBranch);
                } else {
                    branchProducts = data;
                }
    
                const activeProducts = branchProducts.filter((product) => product.Status === "Active");
    
                // Add a serial number property to each product
                const productsWithSerial = activeProducts.map((product, index) => ({
                    ...product,
                    serialNumber: index + 1,
                }));
                setAllProducts(productsWithSerial.reverse());
                //   setAllProducts(data.data);
                setDeleteSelected(false);
                console.log(productsWithSerial, "productsWithSerial");
              break;
            
            default:
              break;
          }
        } else {
          if (index + 1 > 1) {
            console.error(
              `Error loading data for API ${index + 1}:`,
              result.reason
            );
            handleError(
              `Failed to load data for API ${index + 1}: ${result.reason}`
            );
          }
        }
      });
    } catch (error) {
      console.error("Error loading data:", error);
      handleError("Error loading data. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadData();
  }, [clientCode]);

  const handleError = (message) => {
    setErrorMessage(message);
    setShowModal(true); // Open the modal
  };
  const reloadData = () => {
    setShowModal(false); // Close the modal
    loadData(); // Reload data
  };


    const fetchCategories = () => {
        const formData = {
            ClientCode: clientCode,
        };
        fetch(a125, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(formData),
        })
            .then((res) => res.json())
            .then((response) => {
                setAllCategories(response);
            });
    };

    useEffect(() => {
        // fetchCategories();
    }, []);
    const fetchProductTypes = async () => {
        const formData = {
            ClientCode: clientCode,
        };
        await fetch(a128, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(formData),
        })
            .then((res) => res.json())
            .then((response) => {
                setAllProductTypes(response);
            });
    };

    useEffect(() => {
        // fetchProductTypes();
    }, []);
    const fetchCollectonData = async () => {
        const formData = {
            ClientCode: clientCode,
        };
        await fetch(a131, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(formData),
        })
            .then((res) => res.json())
            .then((response) => {
                setAllCollectionTypes(response);
            });
    };

    useEffect(() => {
        // fetchCollectonData();
    }, []);

    let Entryby_Staff_id = parseInt(adminLoggedIn);

    const fetchAllProducts = async () => {
        // setLoading(true);
        const formData = {
            ClientCode: clientCode,
        };
        try {
            const response = await fetch(a181, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(formData),
            });
            const data = await response.json();

            console.log('check all data', data)
            let branchProducts = data;
            console.log(branchProducts, "branchProducts");
            // Add a serial number property to each product
            if (selectBranch === "Home" || selectBranch === "Branch 2") {
                branchProducts = data.filter((x) => x.BranchName === selectBranch);
            } else {
                branchProducts = data;
            }

            const activeProducts = branchProducts.filter((product) => product.Status === "Active");

            // Add a serial number property to each product
            const productsWithSerial = activeProducts.map((product, index) => ({
                ...product,
                serialNumber: index + 1,
            }));

            // const productsWithSerial = branchProducts.map((product, index) => ({
            //     ...product,
            //     serialNumber: index + 1,
            // }));

            // setAllProducts(productsWithSerial.reverse()); // Assuming data.data is an array of products
            setAllProducts(productsWithSerial.reverse());
            //   setAllProducts(data.data);
            setDeleteSelected(false);
            console.log(productsWithSerial, "productsWithSerial");
            setLoading(false);
        } catch (error) {
            console.error("Error fetching products:", error);
            setLoading(false); // Make sure to set loading to false even on error
        }
    };
    //   const productsData = useSelector((state) => state.reducer7.products);
    // console.log("Fetched Products:", productsData);
    //   useEffect(() => {
    //     setAllProducts(productsData);
    //   }, [allProducts]);
    useEffect(() => {
        // setLoading(true);
        window.scrollTo(0, 0);
        // fetchAllProducts();
        // fetchData();
    }, []);
    useEffect(() => {
        if (Entryby_Staff_id == 1) {
            setSelectBranch("Home");
        } else if (Entryby_Staff_id == 2) {
            setSelectBranch("Branch2");
        }
    }, []);
    // console.log(allProducts);
    // console.log(allProducts);
    // useEffect(() => {}, [currentPage]);

    const indexOfLastProduct = currentPage * productsPerPage;
    const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
    const currentProducts = filteredProducts.slice(
        indexOfFirstProduct,
        indexOfLastProduct
    );

    const totalPages = Math.ceil(filteredProducts.length / productsPerPage);

    const goToNextPage = () => {
        setCurrentPage((prevPage) => prevPage + 1);
        console.log("nextClick");
    };

    const goToPreviousPage = () => {
        setCurrentPage((prevPage) => prevPage - 1);
        console.log("prevClick");
    };
    const handleCheckboxChange = (productId) => {
        if (productId === "all") {
            // Select/deselect all checkboxes
            setSelectAll(!selectAll);
            setSelectedProducts(selectAll ? [] : allProducts.map((x) => x.Id));
        } else {
            // Check if the product is already selected
            const isSelected = selectedProducts.includes(productId);

            // If the product is already selected, remove it from the list
            if (isSelected) {
                setSelectedProducts((prevSelected) =>
                    prevSelected.filter((Id) => Id !== productId)
                );
            } else {
                // If the product is not selected, add it to the list
                setSelectedProducts((prevSelected) => [...prevSelected, productId]);
            }
        }
    };

    const printAll = () => {
        const selectedProductData = allProducts.filter((x) =>
            selectedProducts.includes(x.Id)
        );
        printPDF(selectedProductData);
        console.log("button", selectedProductData);
    };
    // Skkhandre New design
    const printPDF = async (products) => {
        await GenerateLabel(products, labelFormat);
    };

    const printList = () => {
        let selectedProductData;
        if (selectedProducts.length === 0) {
            selectedProductData = currentProducts;
        } else {
            selectedProductData = currentProducts.filter((x) =>
                selectedProducts.includes(x.Id)
            );
        }

        printListAll(selectedProductData);
    };


    const printListAll = async (data) => {
        const doc = new jsPDF();
        const pageWidth = doc.internal.pageSize.getWidth();
        const pageHeight = doc.internal.pageSize.getHeight();
        const startX = 10; // Adjusted startX value for the serial number column
        let startY = 20;
        const lineHeight = 5;
        const margin = 5;
        const serialNumberWidth = 20; // Width for the serial number column
        const columnWidth =
            (pageWidth - startX - serialNumberWidth - 10 * margin) / 10;

        doc.setFont("helvetica", "normal");
        // doc.setFontSize(12);
        doc.setFontSize(8);

        const generateHeader = () => {
            doc.text("S. No.", startX, startY); // Serial Number
            doc.text("Design Name", startX + columnWidth, startY);
            doc.text("SKU", startX + 3 * columnWidth, startY);
            doc.text("Gross Wt", startX + 4.2 * columnWidth, startY);
            doc.text("Net Wt", startX + 5.3 * columnWidth, startY);
            doc.text("Item Code", startX + 6.5 * columnWidth, startY);
            doc.text("Barcode No", startX + 8 * columnWidth, startY);
            // doc.text("M Fixed Amt", startX + 7 * columnWidth, startY);
            // doc.text("M Fix Wastage", startX + 8.5 * columnWidth, startY);
            // doc.text("M Percentage", startX + 10 * columnWidth, startY);
            // doc.text("M per_gram", startX + 11.5 * columnWidth, startY);
            // doc.text("stoneAmount", startX + 13 * columnWidth, startY);
            doc.text("Tid", startX + 9.5 * columnWidth, startY);
        };
        const totalNetWt = data.reduce(
            (total, item) => total + (parseFloat(item.netWt) || 0),
            0
        );
        const totalGrossWt = data.reduce(
            (total, item) => total + (parseFloat(item.grosswt) || 0),
            0
        );
        // Generate header on the first page
        generateHeader();
        // doc.text(
        //     `Total Net Wt: ${totalNetWt.toFixed(3)} gm`,
        //     startX + 5 * columnWidth,
        //     startY - 10
        // );
        // doc.text(
        //     `Total Gross Wt: ${totalGrossWt.toFixed(3)} gm`,
        //     startX,
        //     startY - 10
        // );
        // Generate data rows


        let y = startY + lineHeight + margin;
        data.forEach((item, index) => {
            // Check if we need to start a new page
            if (index > 0 && y + lineHeight > pageHeight - margin) {
                doc.addPage();
                startY = 20; // Reset startY for the new page
                // Generate header on each new page
                generateHeader();
                y = startY + lineHeight + margin; // Update y position for the new page
            }

            const serialNumber = index + 1;
            doc.text(serialNumber.toString(), startX, y);
            doc.text(
                item.DesignName ? item.DesignName.toString().substr(0, 8) : "N/A",
                startX + columnWidth,
                y
            );
            doc.text(
                item.SKU ? item.SKU.toString() : "N/A",
                startX + 3 * columnWidth,
                y
            );
            doc.text(
                item.GrossWt ? item.GrossWt.toString() : "N/A",
                startX + 4.2 * columnWidth,
                y
            );
            doc.text(
                item.NetWt ? item.NetWt.toString() : "N/A",
                startX + 5.3 * columnWidth,
                y
            );
            doc.text(
                item.ItemCode ? item.ItemCode.toString() : "N/A",
                startX + 6.5 * columnWidth,
                y
            );
            // doc.text(
            //   item.making_Fixed_Amt ? item.making_Fixed_Amt.toString() : "N/A",
            //   startX + 7 * columnWidth,
            //   y
            // );
            // doc.text(
            //   item.making_Fixed_Wastage
            //     ? item.making_Fixed_Wastage.toString()
            //     : "N/A",
            //   startX + 8.5 * columnWidth,
            //   y
            // );
            // doc.text(
            //   item.making_Percentage ? item.making_Percentage.toString() : "N/A",
            //   startX + 10 * columnWidth,
            //   y
            // );
            // doc.text(
            //   item.making_per_gram ? item.making_per_gram.toString() : "N/A",
            //   startX + 11.5 * columnWidth,
            //   y
            // );
            // doc.text(
            //   item.stoneAmount ? item.stoneAmount.toString() : "N/A",
            //   startX + 13 * columnWidth,
            //   y
            // );
            doc.text(
                item.BranchName ? item.BranchName.toString() : "N/A",
                startX + 8 * columnWidth,
                y
            );
            doc.text(
                item.TIDNumber ? item.TIDNumber.toString() : "N/A",
                startX + 9.5 * columnWidth,
                y
            );
            y += lineHeight + margin;
        });

        // Add page numbers
        const totalPages = doc.internal.getNumberOfPages();
        for (let i = 1; i <= totalPages; i++) {
            doc.setPage(i);
            doc.text(`Page ${i} of ${totalPages}`, pageWidth - 50, pageHeight - 10);
        }

        // Get PDF data as Uint8Array
        const pdfData = doc.output();

        // Create a new Blob from the PDF data
        const pdfBlob = new Blob([pdfData], { type: "application/pdf" });

        // Create a URL from the Blob
        const pdfUrl = URL.createObjectURL(pdfBlob);

        // Open the PDF in a new tab
        window.open(pdfUrl, "_blank");
    };

    const filterOrders = () => {
        let filtered = allProducts;

        if (labelCode !== "") {
            filtered = filtered.filter(
                (product) => product.ItemCode && product.ItemCode.includes(labelCode)
            );
        }
        if (barCode !== "") {
            filtered = filtered.filter(
                (product) => product.RFIDCode && product.RFIDCode.includes(barCode)
            );
        }

        if (categoryName == "") {
            // setCategoryName(""),
            setProductName(""),
                setCollectionName(""),
                setFilteredProducts(allProducts);
            // console.log("categoryName", categoryName);
        }
 
        if (categoryName !== "") {
            filtered = filtered.filter(
                (product) =>
                    product.CategoryName &&
                    product.CategoryName.includes(categoryNameSelected)
            );
        }
        if (productName !== "") {
            console.log("filteredProductsTypes1", filtered);
            filtered = filtered.filter(
                (product) =>
                    // product.itemType && product.itemType.includes(productNameSelected)
                    product.ProductId && product.ProductId === productTypeIdSelected
            );
            console.log("filteredProductsTypes2", filtered);
        }
        if (collectionName !== "") {
            filtered = filtered.filter(
                (product) =>
                    product.DesignName &&
                    product.DesignName.includes(collectionNameSelected)
            );
        }

        if (fromDate !== "" && toDate !== "") {
            filtered = filtered.filter((product) => {
                const createdDate = new Date(product.CreatedOn);
                return (
                    createdDate >= new Date(fromDate) && createdDate <= new Date(toDate)
                );
            });
        }

        setFilteredProducts(filtered);
        setCurrentPage(1); // Reset to the first page after filtering
    };
    console.log("Filtered", filteredProducts);
    useEffect(() => {
        filterOrders();
        window.scrollTo(0, 0);
        // console.log("filtered", filteredProducts);
        // console.log("ptype", allProductTypes);
    }, [
        categoryName,
        productName,
        collectionName,
        labelCode,
        barCode,
        allProducts,
        fromDate,
        toDate,
    ]);
    let categoryId = parseInt(categoryName.split(",")[0]);
    let categoryNameSelected = categoryName.split(",")[1];
    const filteredProductTypes = allProductTypes.filter(
        (product) => product.CategoryId == categoryId
    );
    let productNameSelected = productName.split(",")[1];
    let productTypeIdSelected = parseInt(productName.split(",")[0]);
    let collectionNameSelected = collectionName.split(",")[1];
    const filteredCollection = allCollectionTypes.filter(
        (product) => product.ProductId == productTypeIdSelected
    );
    console.log("current", currentProducts);

    // const filteredCollection = collectionTypeData.filter(
    //   (product) => product.productType == productTypeName
    // );
    // console.log(filteredProducts);
    const handleDeleteCheckboxChange = (productId, itemCode) => {
        let updatedCheckedProducts = [...checkedProducts];
        let updatedSelectedItemCodes = [...selectedItemCodes];

        if (updatedCheckedProducts.includes(productId)) {
            updatedCheckedProducts = updatedCheckedProducts.filter(
                (id) => id !== productId
            );
            updatedSelectedItemCodes = updatedSelectedItemCodes.filter(
                (code) => code !== itemCode
            );
        } else {
            updatedCheckedProducts.push(productId);
            updatedSelectedItemCodes.push(itemCode);
        }

        if (updatedCheckedProducts.length > 0) {
            setDeleteSelectedButton(true);
        } else {
            setDeleteSelectedButton(false);
        }

        setCheckedProducts(updatedCheckedProducts);
        setSelectedItemCodes(updatedSelectedItemCodes);
    };
    const selectedItems = selectedItemCodes.map((itemCode) => ({
        ItemCode: itemCode,
    }));


    const deleteAllProducts = async (itemsToDelete) => {
        const deletAllItemsList = itemsToDelete.map((x) => {
            return { ...x, ClientCode: clientCode };
        });
        try {
            const response = await fetch(a178, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(deletAllItemsList),
            });

            const rcvdData = await response.json();
            console.log("AllItemsDeleted", rcvdData);
            console.log("Response:", rcvdData);
            // if (rcvdData.status == "Success") {
            // Deletion was successful
            // console.log("Item deleted successfully:", response.message);
            // alert(rcvdData.Message);
            setSelectedItemCodes([]);
            setCheckedProducts([]);
            fetchAllProducts();
            // You can show an alert or notification here
            // alert(data.message);

            setDeleteSelectedButton(false);
            // } else {
            // Handle the case where deletion failed
            // console.error("Failed to delete item:", response.Message);

            // You can show an error message to the user
            // alert("Failed to delete item: " + response.Message);
            // }
        } catch (error) {
            // Handle any network or fetch API errors
            console.error("An error occurred:", error);

            // Show an error message to the user
            // alert("An error occurred while deleting the item.");
        }
    };
    console.log("productName", productName);

    return (
        <div>
            <AdminHeading />


            {loading ? (
        // Show spinner while loading
        <div className="spinner-container">
          <ClipLoader size={50} color={"#123abc"} loading={loading} />
          <p>Loading data...</p>
        </div>
      ) : (
        <div>
          <ErrorModal
            isOpen={showModal}
            onRequestClose={() => {
              setShowModal(false); // Close the modal
              navigate("/adminhome"); // Redirect to /adminhome
            }}
            onReload={reloadData} // Pass reload function
            message={errorMessage}
          />


<div style={{ paddingTop: "130px" }}>
                <AdminBreadCrump
                    title={"Inventory"}
                    companyName={"Loyalstring"}
                    module={"E-commerce"}
                    page={"Inventory"}
                />
                <div className="adminAddCategoryMainBox">
                    <div className="adminAddCategoryInnerBox">
                        <div className={loading == true ? "loading" : "none"}>
                            {/* <h1>Loading...</h1> */}
                            {/* <InfinitySpin width="200" color="#4fa94d" /> */}
                            <MagnifyingGlass
                                visible={true}
                                height="80"
                                width="80"
                                ariaLabel="MagnifyingGlass-loading"
                                wrapperStyle={{}}
                                wrapperClass="MagnifyingGlass-wrapper"
                                glassColor="#c0efff"
                                color="#e15b64"
                            />
                        </div>

                        {!loading == true ? (
                            <div>
                                <div
                                    style={{
                                        width: "100%",
                                        justifyContent: "left",
                                        flexWrap: "wrap",
                                    }}
                                    className="adminAllProductsFilterBox"
                                >
                                    <div className="adminAllProductsFilterLabelBox">
                                        {/* <div className="adminAllOrderLeftBox"> */}
                                        <Grid container spacing={{ xs: 2, md: 4 }}>
                                            <Grid item xs={6} sm={3}>
                                                <input
                                                    style={{ width: "100%" }}
                                                    type="text"
                                                    placeholder="Label..."
                                                    value={labelCode}
                                                    onChange={(e) => {
                                                        setLabelCode(e.target.value.toUpperCase());
                                                        setCurrentPage(1);
                                                        filterOrders();
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={6} sm={3}>
                                                <input
                                                    style={{ width: "100%" }}
                                                    type="text"
                                                    placeholder="RFID Code"
                                                    value={barCode}
                                                    onChange={(e) => {
                                                        setBarCode(e.target.value.toUpperCase());
                                                        setCurrentPage(1);
                                                        filterOrders();
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={6} sm={3}>
                                                <select
                                                    style={{ width: "100%" }}
                                                    value={categoryName}
                                                    onChange={(e) => {
                                                        setCategoryName(e.target.value);
                                                        setCurrentPage(1);
                                                    }}
                                                >
                                                    <option value="">Select Category</option>
                                                    {allCategories.map((x) => {
                                                        return (
                                                            <option value={`${x.Id},${x.CategoryName}`}>
                                                                {x.CategoryName}
                                                            </option>
                                                        );
                                                    })}
                                                </select>
                                            </Grid>
                                            <Grid item xs={6} sm={3}>
                                                <select
                                                    style={{ width: "100%" }}
                                                    value={productName}
                                                    onChange={(e) => {
                                                        setProductName(e.target.value);
                                                        setCurrentPage(1);
                                                        setCollectionName("");
                                                    }}
                                                >
                                                    <option value="">Select Product Type</option>
                                                    {filteredProductTypes.map((x) => {
                                                        return (
                                                            <option value={`${parseInt(x.Id)},${x.ProductName}`}>
                                                                {x.ProductName}
                                                            </option>
                                                        );
                                                    })}
                                                </select>
                                            </Grid>
                                        </Grid>
                                    </div>
                                    <div className="adminAllProductsFilterDatesBox">
                                        <Grid container spacing={{ xs: 2, md: 4 }}>
                                            <Grid xs={6} sm={3} item>
                                                <div className="adminAllProductsFilterCategoryBox">
                                                    <select
                                                        style={{ width: "100%", marginTop: "0px" }}
                                                        value={collectionName}
                                                        onChange={(e) => {
                                                            setCollectionName(e.target.value);
                                                            setCurrentPage(1);
                                                        }}
                                                    >
                                                        <option value="">Select Design</option>
                                                        {filteredCollection.map((x) => {
                                                            return (
                                                                <option value={`${parseInt(x.Id)},${x.DesignName}`}>
                                                                    {x.DesignName}
                                                                </option>
                                                            );
                                                        })}
                                                    </select>
                                                </div>
                                            </Grid>
                                            <Grid xs={6} sm={3} item>
                                                <input
                                                    type="date"
                                                    style={{ width: "100%" }}
                                                    placeholder="From Date"
                                                    value={fromDate}
                                                    onChange={(e) => setFromDate(e.target.value)}
                                                />
                                            </Grid>
                                            <Grid xs={6} sm={3} item>
                                                <input
                                                    style={{ width: "100%" }}
                                                    type="date"
                                                    placeholder="To Date"
                                                    value={toDate}
                                                    onChange={(e) => setToDate(e.target.value)}
                                                />
                                            </Grid>
                                            <Grid xs={6} sm={3} item>
                                                <AiOutlineEdit
                                                    className="labelledListEditIcon"
                                                    style={{ padding: "5px" }}
                                                    size={"1.5rem"}
                                                    onClick={() => {
                                                        setDeleteSelected(!deleteSelected),
                                                            setSelectedItemCodes([]),
                                                            setCheckedProducts([]),
                                                            setDeleteSelectedButton(false);
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </div>
                                    <div className="adminAllLabelledListButtonBox">
                                        <button onClick={printAll}>Print Label</button>
                                        <button onClick={printList}>Print List</button>

                                        <button
                                            onClick={() => {
                                                setSelectedProducts([]),
                                                    setSelectAll(false),
                                                    setCategoryName(""),
                                                    setProductName(""),
                                                    setCollectionName(""),
                                                    setFromDate(""),
                                                    setToDate(""),
                                                    setFilteredProducts(allProducts);
                                            }}
                                        >
                                            Reset
                                        </button>
                                        {deleteSelectedButton ? (
                                            <button
                                                onClick={() => deleteAllProducts(selectedItems)}
                                                style={{ backgroundColor: "#c14456", color: "white" }}
                                            >
                                                Delete Selected
                                            </button>
                                        ) : null}
                                    </div>
                                </div>


                                <div>
                                    <TableContainer sx={{ borderSpacing: '0', borderCollapse: 'collapse' }}>
                                        <table size="small" style={{ borderRadius: '4px', borderCollapse: 'collapse', borderSpacing: '0' }}>
                                            <thead style={{ backgroundColor: '#627383', color: '#FFFFFF' }}>
                                                <tr>
                                                    {sortedColumns.map((column) => (
                                                        <th key={column.key} style={{
                                                            padding: '3px',
                                                            border: '1px solid #ccc',
                                                            textAlign: 'center',
                                                            backgroundColor: '#627383',
                                                            color: '#fff',
                                                            fontSize: '12px',
                                                            fontWeight: 'normal',
                                                        }}>
                                                            {column.label}
                                                        </th>
                                                    ))}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {currentProducts.map((product, idx) => (
                                                    <tr key={product.Id} style={{ borderBottom: '1px solid #ccc' }}>
                                                        {sortedColumns.map((column) => (
                                                            <td key={column.key} style={tdStyle}>
                                                                {column.type === "checkbox" ? (
                                                                    <input
                                                                        type="checkbox"
                                                                        checked={selectAll || selectedProducts.includes(product.Id)}
                                                                        onChange={() => handleCheckboxChange(product.Id)}
                                                                    />
                                                                ) : column.type === "read" ? (
                                                                    <span style={{ fontSize: '12px' }}>
                                                                        {column.calculate ? column.calculate(product) : product[column.key]}
                                                                    </span>
                                                                ) : null}
                                                            </td>
                                                        ))}
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </TableContainer>
                                </div>


                            </div>
                        ) : null}

                        {/* Pagination controls */}
                        <div className="bulkProductAddingTableMain">
                            <button onClick={goToPreviousPage} disabled={currentPage === 1}>
                                Previous
                            </button>
                            <button
                                onClick={goToNextPage}
                                disabled={currentPage === totalPages}
                            >
                                Next
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="adminInventoryMobilePrintButtonsBox">
                <button onClick={printAll}>Print</button>
                <button onClick={printList}>Print List</button>

                <button
                    onClick={() => {
                        setSelectedProducts([]),
                            setSelectAll(false),
                            setCategoryName(""),
                            setProductName(""),
                            setCollectionName(""),
                            setFromDate(""),
                            setToDate(""),
                            setFilteredProducts(allProducts);
                    }}
                >
                    Reset
                </button>
                {deleteSelectedButton ? (
                    <button
                        onClick={() => deleteAllProducts(selectedItems)}
                        style={{ backgroundColor: "#c14456", color: "white" }}
                    >
                        Delete Selected
                    </button>
                ) : null}
            </div>



          </div>
      )}



           

        </div>
    );
}
