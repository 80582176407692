// import React from "react";
// import { Navigate, Outlet, useLocation } from "react-router-dom";
// import { useSelector } from "react-redux";

// const ProtectedRoute = ({ element: Component, fallback = <Navigate to="/adminpanellogin" /> }) => {
//     const allStates = useSelector((state) => state);
//     const adminLoggedIn = allStates.reducer1;
//     const location = useLocation();

//     const isAuthenticated = adminLoggedIn.Clients || ["2", "3", "4"].includes(adminLoggedIn.toString()) || adminLoggedIn.StatusType === true;
//     console.log('check permission ', location.pathname);
//     // Automatically check permission based on the route's path
//     // const hasPermission = () => {
//     //     return adminLoggedIn?.Modules?.some(
//     //         (module) =>
//     //             module.PageName === location.pathname && module.PagePermission === "enable"
//     //     );
//     // };
//     const hasPermission = () => {
//         const currentPath = location.pathname.startsWith("/")
//             ? location.pathname.slice(1)
//             : location.pathname;
    
//         return adminLoggedIn?.Modules?.some(
//             (module) => module.PageName === currentPath && module.PagePermission === "enable"
//         );
//     };
//     if (!isAuthenticated) {
//         return fallback;
//     }

//     if (!hasPermission()) {
//         alert("You do not have permission to access this page.");
//         return <Navigate to="/adminhome" />; // Redirect to a fallback page
//     }

//     return Component ? <Component /> : <Outlet />;
// };

// export default ProtectedRoute;


// import React from "react";
// import { Navigate, Outlet, useLocation } from "react-router-dom";
// import { useSelector } from "react-redux";

// const ProtectedRoute = ({ element, fallback = <Navigate to="/adminpanellogin" /> }) => {
//     const allStates = useSelector((state) => state);
//     const adminLoggedIn = allStates.reducer1;
//     const location = useLocation();

//     const isAuthenticated = adminLoggedIn.Clients || ["2", "3", "4"].includes(adminLoggedIn.toString()) || adminLoggedIn.StatusType === true;
//     console.log('check permission ', location.pathname);

//     const hasPermission = () => {
//         const currentPath = location.pathname.startsWith("/")
//             ? location.pathname.slice(1)
//             : location.pathname;

//         return adminLoggedIn?.Modules?.some(
//             (module) => module.PageName === currentPath && module.PagePermission === "enable"
//         );
//     };

//     if (!isAuthenticated) {
//         return fallback;
//     }

//     if (!hasPermission()) {
//         alert("You do not have permission to access this page.");
//         return <Navigate to="/adminhome" />; // Redirect to a fallback page
//     }

//     // Render the element passed as prop
//     return element || <Outlet />;
// };

// export default ProtectedRoute;



import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

const ProtectedRoute = ({ element, fallback = <Navigate to="/adminpanellogin" /> }) => {
    const allStates = useSelector((state) => state.reducer1);
    const location = useLocation();
    const allStates1 = useSelector((state) => state);

    const adminLoggedIn = allStates1.reducer1;

    const isSuperAdmin = adminLoggedIn?.SuperAdmin === 1; // Check if user is super admin
    const modules = allStates?.Modules || []; // Get modules array, defaulting to empty if undefined

    console.log('check permission  ', adminLoggedIn, '  ' , isSuperAdmin)
    const isAuthenticated = adminLoggedIn.Clients || ["2", "3", "4"].includes(adminLoggedIn.toString()) || adminLoggedIn.StatusType === true;
    const hasPermission = () => {
        const currentPath = location.pathname.startsWith("/")
            ? location.pathname.slice(1)
            : location.pathname;

        return modules.some(
            (module) => module.PageName === currentPath && module.PagePermission === "enable"
        );
    };

    // If modules are empty, allow access only if user is super admin
    if (modules.length === 0 && !isSuperAdmin) {
        alert("You do not have permission to access this page ..");
        return <Navigate to="/adminhome" />;
    }

    // Proceed to check authentication and permissions
    if (!isAuthenticated) {
        return fallback;
    }

    // If modules are not empty, check permissions normally
    if (modules.length > 0 && !hasPermission()) {
        alert("You do not have permission to access this page.");
        return <Navigate to="/adminhome" />;
    }

    return element;
};

export default ProtectedRoute;

