import React, { useEffect, useState } from "react";
import AdminHeading from "../Heading/AdminHeading";
import AdminBreadCrump from "../Heading/AdminBreadCrump";
import "../../PagesStyles/AdminMasters.css";
import { TbCircleNumber1 } from "react-icons/tb";
import {
  a100,
  a104,
  a107,
  a108,
  a109,
  a110,
  a111,
  a112,
  a119,
  a120,
  a121,
  a18,
  a35,
  a7,
  a95,
  a96,
  a97,
  a98,
  a99,
} from "../../../Api/RootApiPath";
import { useSelector } from "react-redux";
import { RiListUnordered, RiPlayListAddLine } from "react-icons/ri";
import AlertMessage from "../../../Other Functions/AlertMessage";
import { financialYearsList } from "../../../Api/FinancialYearsList";
import {allCountriesList} from "../../../Api/CountriesAllList";
import {allStateList} from "../../../Api/StateList";

export default function AdminAddTax() {
  const [active, setActive] = useState("List");
  const [showError, setShowError] = useState(false);
  const [messageType, setMessageType] = useState("");
  const [messageToShow, setMessageToShow] = useState("");
  const [loading, setLoading] = useState(false);

  const [allCategories, setAllCategories] = useState([]);
  const [newCategory, setNewCategory] = useState({
    CountryId: "",
    StateId: "",
    TaxName: "",
    Rate: "",
    TaxType: "",
    Description: "",
    FinancialYearFormat: "",

    OldEntry: false,
  });
  const [allCompaniesList, setAllCompaniesList] = useState([]);
  const [allBranchesList, setAllBranchesList] = useState([]);
  const [allDepartmentsList, setAllDepartmentsList] = useState([]);
  const [allRolesList, setAllRolesList] = useState([]);
  // const [allCountriesList, setAllCountriesList] = useState([]);
  // const [allStatesList, setAllStatesList] = useState([]);
  const allStates = useSelector((state) => state);
  const adminLoggedIn = allStates.reducer1;
  //   let Entryby_Staff_id = parseInt(adminLoggedIn);
  const clientCode = adminLoggedIn.ClientCode;

  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  const fetchAllCategory = async () => {
    const formData = {
      ClientCode: clientCode,
    };
    const response = await fetch(a119, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    });
    const data = await response.json();
    console.log(data, "data,");
    try {
      if (data.length > 0) {
        setAllCategories(data);
      } else {
        setActive("addNew");
        document
          .getElementById("addCategoryListTitle")
          .classList.add("activeCategoryTitle");
        document
          .getElementById("addCategoryListLogo")
          .classList.add("activeCategoryLogo");
        document.getElementById("addCategoryListTitle").click();
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchAllCategory();
  }, []);
  const fetchAllCompanies = async () => {
    const formData = {
      ClientCode: clientCode,
    };
    const response = await fetch(a95, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    });
    const data = await response.json();
    console.log(data, "data,");
    try {
      if (data.length > 0) {
        setAllCompaniesList(data);
      } else {
        // alert("Please Add Company First");
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchAllCompanies();
  }, []);

  const fetchAllBranches = async () => {
    const formData = {
      ClientCode: clientCode,
    };
    const response = await fetch(a98, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    });
    const data = await response.json();
    console.log(data, "data,");
    try {
      if (data.length > 0) {
        setAllBranchesList(data);
      } else {
        // alert("Please Add Company First");
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchAllBranches();
  }, []);

  const fetchAllDepartments = async () => {
    const formData = {
      ClientCode: clientCode,
    };
    const response = await fetch(a104, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    });
    const data = await response.json();
    console.log(data, "data,");
    try {
      if (data.length > 0) {
        setAllDepartmentsList(data);
      } else {
        // alert("Please Add Company First");
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchAllDepartments();
  }, []);

  const fetchAllRoles = async () => {
    const formData = {
      ClientCode: clientCode,
    };
    const response = await fetch(a107, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    });
    const data = await response.json();
    console.log(data, "data,");
    try {
      if (data.length > 0) {
        setAllRolesList(data);
      } else {
        // alert("Please Add Company First");
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchAllRoles();
  }, []);
  console.log(allCategories, "allCategories");
  console.log(allCompaniesList, "allCompaniesList");
  console.log(allDepartmentsList, "allDepartmentsList");
  console.log(allBranchesList, "allBranchesList");
  console.log(allRolesList, "allRolesList");

  const handleNewCategoryChange = (e) => {
    const { name, value } = e.target;
    // Update the edited data in the state
    setNewCategory({ ...newCategory, [name]: value });
  };
  console.log(newCategory, "newCategory");
  console.log(newCategory, "newCategory");

  const addNewCategory = async (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = {
      ClientCode: clientCode,
      CountryId: newCategory.CountryId,
      StateId: newCategory.StateId,
      Rate: newCategory.Rate,
      TaxType: newCategory.TaxType,
      TaxName: newCategory.TaxName,
      Description: newCategory.Description,
      FinancialYearFormat: newCategory.FinancialYearFormat,

      ...(newCategory.OldEntry ? { Id: newCategory.Id } : {}),
    };
    console.log(formData, "formData to send");
    try {
      const response = await fetch(
        !newCategory.OldEntry ? a121 : a120,
        // a96,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );
      const data = await response.json();
      fetchAllCategory();
      setActive("List");
      setNewCategory({
        CountryId: "",
        StateId: "",
        TaxName: "",
        Rate: "",
        TaxType: "",
        Description: "",
        FinancialYearFormat: "",
        OldEntry: false,
      });
      if (data.message) {
        // alert(data.message);
        setMessageType("error");
        setMessageToShow(data.message);
        setShowError(true);
        setActive("AddNew");
      } else {
        setMessageType("success");
        setMessageToShow("Category Added Successfully");
        setShowError(true);
      }
      setLoading(false);
      console.log(data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setShowError(false);
    }, 2000);
  }, [showError]);
  const handleEditData = (data) => {
    console.log(data, "data");
    console.log(data, "data");
    console.log(data, "data");
    setNewCategory({ ...data, OldEntry: true });
    setActive("AddNew");
  };
  return (
    <div>
      <AdminHeading />
      <div className="adminMainBodyBox">
        {showError ? (
          <AlertMessage message={messageToShow} type={messageType} />
        ) : null}
        <AdminBreadCrump
          title={"Add Taxes"}
          companyName={"Loyalstring"}
          module={"User Masters"}
          page={"Taxes"}
        />
        <div className="adminAddCategoryMainBox">
          <div className="adminAddCategoryInnerBox">
            <div className="adminAddCategoryInnerBoxTitlesBox">
              <div
                onClick={() => {
                  setActive("List");
                }}
                className={
                  active === "List"
                    ? "adminAddCategoryInnerBoxTitle"
                    : "adminAddCategoryInnerBoxTitle activeCategoryTitle"
                }
              >
                <div
                  className={
                    active === "List"
                      ? "adminAddCategoryInnerBoxTitleLogo"
                      : "adminAddCategoryInnerBoxTitleLogo activeCategoryLogo"
                  }
                >
                  {/* 01 */}
                  <RiListUnordered />
                </div>
                <p>All Taxes</p>
              </div>

              <div
                id="addCategoryListTitle"
                onClick={() => setActive("AddNew")}
                className={
                  active === "AddNew"
                    ? "adminAddCategoryInnerBoxTitle"
                    : "adminAddCategoryInnerBoxTitle activeCategoryTitle"
                }
              >
                <div
                  id="addCategoryListLogo"
                  className={
                    active === "AddNew"
                      ? "adminAddCategoryInnerBoxTitleLogo"
                      : "adminAddCategoryInnerBoxTitleLogo activeCategoryLogo"
                  }
                >
                  {/* 02 */}
                  <RiPlayListAddLine />
                </div>
                <p>Add Tax</p>
              </div>
            </div>
            <div
              className={
                active === "List" ? "adminCategoryListMainBox" : "none"
              }
            >
              <table>
                <thead>
                  <tr>
                    <th>Edit</th>
                    <th>Sr.No</th>
                    <th>Country</th>
                    <th>State</th>
                    <th>TaxName</th>
                    <th>Percent</th>
                    <th>TaxType</th>
                    <th>Financial Year</th>
                    <th>Description</th>
                  </tr>
                </thead>
                <tbody>
                  {allCategories.map((x, index) => (
                    <tr key={x.id}>
                      <td>
                        <button
                          className="adminAddCategoryEditButton"
                          // onClick={() => handleEditClick(x.id)}
                          onClick={() => handleEditData(x)}
                        >
                          Edit
                        </button>
                      </td>
                      <td>{index + 1}</td>
                      <td>{x.Country}</td>
                      <td>{x.State}</td>
                      <td>{x.TaxName}</td>
                      <td>{x.Rate}</td>
                      <td>{x.TaxType}</td>
                      <td>{x.FinancialYearFormat}</td>
                      <td>{x.Description}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div
              className={
                active !== "List" ? "adminCategoryAddCategoryMainBox" : "none"
              }
            >
              <p>Add New Tax</p>
              <form onSubmit={addNewCategory}>
                <div
                  style={{
                    gridTemplateColumns: "repeat(4,1fr)",
                    columnGap: "40px",
                  }}
                  className="adminCategoryAddCategoryInnerBox"
                >
                  <label>
                    Country <sup>*</sup>
                  </label>
                  <select
                    name="CountryId"
                    required
                    value={newCategory.CountryId}
                    onChange={handleNewCategoryChange}
                  >
                    <option value={""}>Select Country</option>
                    {allCountriesList.map((x) => (
                      <option value={x}>{x}</option>
                    ))}
                  </select>
                  <label>
                    State <sup>*</sup>
                  </label>
                  {/*<select*/}
                  {/*  name="StateId"*/}
                  {/*  required*/}
                  {/*  value={newCategory.StateId}*/}
                  {/*  onChange={handleNewCategoryChange}*/}
                  {/*>*/}
                  {/*  <option value={""}>Select State</option>*/}
                  {/*  {allStatesList.map((x) => (*/}
                  {/*    <option value={x.Id}>{x.StateName}</option>*/}
                  {/*  ))}*/}
                  {/*</select>*/}
                  <input
                      // style={{ width: "30vw" }}
                      type="text"
                      name="StateId"
                      value={newCategory.StateId}
                      onInput={handleNewCategoryChange}
                      list="supplierNamesList"
                  />
                  <datalist id="supplierNamesList">
                    {allStateList.map((item, index) => (
                        <option
                            key={index}
                            value={item}
                        />
                    ))}
                  </datalist>
                  <label>
                    Tax Name <sup>*</sup>
                  </label>
                  <input
                    name="TaxName"
                    value={newCategory.TaxName}
                    onChange={handleNewCategoryChange}
                    type="text"
                  />
                  <label>
                    Tax Type <sup>*</sup>
                  </label>

                  <select
                    name="TaxType"
                    value={newCategory.TaxType}
                    onChange={handleNewCategoryChange}
                    required
                  >
                    <option value={""}>Select Tax Type</option>
                    <option value={"GST"}>GST</option>
                    <option value={"SALES TAX"}>SALES TAX</option>
                    <option value={"VAT"}>VAT</option>
                    <option value={"EXCISE"}>EXCISE</option>
                    <option value={"INCOME TAX"}>INCOME TAX</option>
                    <option value={"TCS"}>TCS</option>
                    <option value={"TDS"}>TDS</option>
                    <option value={"GST ON REPAIR"}>GST ON REPAIR</option>
                    <option value={"GST ON ORDER"}>GST ON ORDER</option>
                  </select>
                  <label>
                    Percentage % <sup>*</sup>
                  </label>
                  <input
                    name="Rate"
                    value={newCategory.Rate}
                    onChange={handleNewCategoryChange}
                    type="text"
                    placeholder="Between 0 to 100"
                  />
                  <label>
                    Financial Year <sup>*</sup>
                  </label>

                  <select
                    name="FinancialYearFormat"
                    value={newCategory.FinancialYearFormat}
                    onChange={handleNewCategoryChange}
                    required="required"
                  >
                    <option value={""}>Choose Financial Year</option>

                    {financialYearsList.map((x, index) => (
                      <option key={index} value={x}>
                        {x}
                      </option>
                    ))}
                  </select>

                  <label>Description</label>
                  <input
                    name="Description"
                    value={newCategory.Description}
                    onChange={handleNewCategoryChange}
                    type="text"
                  />
                </div>
                {!loading ? <button type="submit">Submit</button> : null}
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
