import React, { useRef } from 'react';
import { FaPlus, FaTrash } from 'react-icons/fa'; 
import { TableContainer } from "@mui/material";

const DiamondEntryComponent = ({
  purchaseProduct,
  handleDiamondChange,
  addDiamond,
  deleteDiamond,
  setPurchaseProduct,
  allDiamondAttributes,
  allDiamondSizeWeightRate,
}) => {
  const diamondRefs = useRef([]); // To focus on new row inputs

  const handleAddRow = () => {
    setPurchaseProduct((prevState) => ({
      ...prevState,
      Diamonds: [...(prevState.Diamonds || []), { /* Default diamond values */ }],
    }));
  };

  const handleEnterKey = (e, currentIndex, fieldName) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      
      const fieldOrder = [
        'DiamondShape', 'DiamondClarity', 'DiamondColour', 'DiamondSize', 
        'Sleve', 'DiamondWeight', 'DiamondRate', 'DiamondPieces', 
        'DiamondCut', 'SettingType', 'Certificate', 'DiamondTotalWeight', 
        'DiamondTotalAmount', 'Description'
      ];
      const nextFieldIndex = fieldOrder.indexOf(fieldName) + 1;

      if (nextFieldIndex < fieldOrder.length) {
        const nextField = fieldOrder[nextFieldIndex];
        document.getElementById(`${nextField}-${currentIndex}`).focus();
      } else {
        handleAddRow();
        setTimeout(() => {
          const nextRowIndex = purchaseProduct?.Diamonds.length;
          diamondRefs.current[nextRowIndex]?.focus();
        }, 0);
      }
    }
  };

  return (
    <div className="adminAddProductsPopupInnerBox" style={{ maxWidth: '90vw', overflowX: 'hidden' }}>
    <TableContainer sx={{ borderSpacing: '0', borderCollapse: 'collapse' }}>
      <table size="small" sx={{ borderRadius: '4px', borderCollapse: 'collapse', borderSpacing: '0' }}>
        <thead>
          <tr>
            <th style={thStyle}>Shape</th>
            <th style={thStyle}>Clarity</th>
            <th style={thStyle}>Colour</th>
            <th style={thStyle}>Size</th>
            <th style={thStyle}>Sleve</th>
            <th style={thStyle}>Weight</th>
            <th style={thStyle}>Rate</th>
            <th style={thStyle}>Pieces</th>
            <th style={thStyle}>Cut</th>
            <th style={thStyle}>Setting</th>
            <th style={thStyle}>Certificate</th>
            <th style={thStyle}>Total Weight</th>
            <th style={thStyle}>Total Amount</th>
            <th style={thStyle}>Description</th>
            <th style={{ ...thStyle, width: '40px' }}>
              <FaPlus onClick={handleAddRow} style={{ cursor: 'pointer', color: 'green' }} />
            </th>
          </tr>
        </thead>
        <tbody>
          {purchaseProduct?.Diamonds?.map((diamond, index) => (
            <tr key={index}>
              <td>
                <input
                  value={diamond.DiamondShape}
                  onChange={(e) => handleDiamondChange(index, 'DiamondShape', e.target.value)}
                  onKeyDown={(e) => handleEnterKey(e, index, 'DiamondShape')}
                  list="diamondAttributesShapeList"
                  id={`DiamondShape-${index}`}
                  ref={(el) => (diamondRefs.current[index] = el)}
                />
                <datalist id="diamondAttributesShapeList">
                  {allDiamondAttributes
                    .filter(attr => attr.DiamondAttribute === "DiamondShape")
                    .map((attr, i) => <option key={i} value={attr.DiamondValue} />)}
                </datalist>
              </td>

              <td>
                <input
                  value={diamond.Description}
                  onChange={(e) => handleDiamondChange(index, 'Description', e.target.value)}
                  onKeyDown={(e) => handleEnterKey(e, index, 'Description')}
                  id={`Description-${index}`}
                />
              </td>
              <td>
                <input
                  value={diamond.Description}
                  onChange={(e) => handleDiamondChange(index, 'Description', e.target.value)}
                  onKeyDown={(e) => handleEnterKey(e, index, 'Description')}
                  id={`Description-${index}`}
                />
              </td>
              <td>
                <input
                  value={diamond.Description}
                  onChange={(e) => handleDiamondChange(index, 'Description', e.target.value)}
                  onKeyDown={(e) => handleEnterKey(e, index, 'Description')}
                  id={`Description-${index}`}
                />
              </td>
              <td>
                <input
                  value={diamond.Description}
                  onChange={(e) => handleDiamondChange(index, 'Description', e.target.value)}
                  onKeyDown={(e) => handleEnterKey(e, index, 'Description')}
                  id={`Description-${index}`}
                />
              </td>
              <td>
                <input
                  value={diamond.Description}
                  onChange={(e) => handleDiamondChange(index, 'Description', e.target.value)}
                  onKeyDown={(e) => handleEnterKey(e, index, 'Description')}
                  id={`Description-${index}`}
                />
              </td>
              <td>
                <input
                  value={diamond.Description}
                  onChange={(e) => handleDiamondChange(index, 'Description', e.target.value)}
                  onKeyDown={(e) => handleEnterKey(e, index, 'Description')}
                  id={`Description-${index}`}
                />
              </td>
              <td>
                <input
                  value={diamond.Description}
                  onChange={(e) => handleDiamondChange(index, 'Description', e.target.value)}
                  onKeyDown={(e) => handleEnterKey(e, index, 'Description')}
                  id={`Description-${index}`}
                />
              </td>
              <td>
                <input
                  value={diamond.Description}
                  onChange={(e) => handleDiamondChange(index, 'Description', e.target.value)}
                  onKeyDown={(e) => handleEnterKey(e, index, 'Description')}
                  id={`Description-${index}`}
                />
              </td>
              
              <td>
                <input
                  value={diamond.Description}
                  onChange={(e) => handleDiamondChange(index, 'Description', e.target.value)}
                  onKeyDown={(e) => handleEnterKey(e, index, 'Description')}
                  id={`Description-${index}`}
                />
              </td>
              <td>
                <FaTrash onClick={() => deleteDiamond(index)} style={{ cursor: 'pointer', color: 'red' }} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <style jsx>{`
        @media (max-width: 768px) {
          th, td {
            font-size: 12px;
            padding: 1px;
          }

          input {
            padding: 5px;
          }
        }
      `}</style>
      </TableContainer>
    </div>
  );
};

const thStyle = {
    padding: '5px',
    border: '1px solid #ccc',
    textAlign: 'center',
    fontWeight: '600',
  };
  
  const tdStyle = {
    padding: '5px',
    border: '1px solid #ccc',
    textAlign: 'center',
  };
  
  const inputStyle = {
    width: '100%',
    padding: '3px',
    margin: '0',
    boxSizing: 'border-box',
    borderRadius: '4px',
    border: '1px solid #ccc',
  };
  

  

  
export default DiamondEntryComponent;
