export const financialYearsList = [
  // "2001-02",
  // "2002-03",
  // "2003-04",
  // "2004-05",
  // "2005-06",
  // "2006-07",
  // "2007-08",
  // "2008-09",
  // "2009-10",
  // "2010-11",
  // "2011-12",
  // "2012-13",
  // "2013-14",
  // "2014-15",
  // "2015-16",
  // "2016-17",
  // "2017-18",
  // "2018-19",
  // "2019-20",
  // "2020-21",
  // "2021-22",
  // "2022-23",
  "2023-2024",
  "2024-2025",
  "2025-2026",
  "2026-2027",
  "2027-2028",
  "2028-2029",
  "2029-2030",
  "2030-2031",
  "2031-2032",
  "2032-2033",
  "2033-2034",
  "2034-2035",
  // "2035-36",
  // "2036-37",
  // "2037-38",
  // "2038-39",
  // "2039-40",
  // "2040-41",
  // "2041-42",
  // "2042-43",
  // "2043-44",
  // "2044-45",
  // "2045-46",
  // "2046-47",
  // "2047-48",
  // "2048-49",
  // "2049-50",
  // "2050-51",
  // "2051-52",
  // "2052-53",
  // "2053-54",
  // "2054-55",
  // "2055-56",
  // "2056-57",
  // "2057-58",
  // "2058-59",
  // "2059-60",
  // "2060-61",
  // "2061-62",
  // "2062-63",
  // "2063-64",
  // "2064-65",
  // "2065-66",
  // "2066-67",
  // "2067-68",
  // "2068-69",
  // "2069-70",
  // "2070-71",
  // "2071-72",
  // "2072-73",
  // "2073-74",
  // "2074-75",
];
