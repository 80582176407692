import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import arrowDown from "@iconify/icons-eva/arrow-down-outline";
import arrowUp from "@iconify/icons-eva/arrow-up-outline";
import "./VerticalMenu.css";



const VerticalMenu = ({
  initialModulesData,
  toggleVisibility,
  handleHeaderToggleChange,
  expandedHeaders,
  checkedHeaders,
  handleHeaderClick,
  expandedItems,
  checkedItems,
  handleItemClick,
  handleToggleChange,
  handleSequenceChange,
  handleSelectAll
}) => {

  // Helper function to sort and group PageControls by position
  const groupControlsByPosition = (controls) => {
    const topControls = controls.filter((field) => field.place === "top");
    const middleControls = controls.filter((field) => field.place === "middle");
    const bottomControls = controls.filter((field) => field.place === "bottom");

    console.log('check fields ', topControls, '  ', controls);

    return { topControls, middleControls, bottomControls };
  };

  return (
    <div className="menu-table-container">
      <table className="menu-table">
        <thead>
          <tr>
            <th>Modules</th>
            <th>Enable</th>
          </tr>
        </thead>

        <tbody>
          {initialModulesData.map((section) => (
            <React.Fragment key={section.PageId}>
              {/* Module Header */}
              <tr
                onClick={() => handleHeaderClick(section.heading)}
                style={{ cursor: "pointer", backgroundColor: "#e0e0e0" }}
              >
                <td style={{ color: "#1045be" }}>
                  {section.PageDisplayName}
                  <span className="dropdown-arrow">
                    <Icon icon={expandedHeaders[section.heading] ? arrowUp : arrowDown} />
                  </span>
                </td>
                <td>
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={section.items.some(item => item.PagePermission === 'enable')}
                      onChange={() => handleHeaderToggleChange(section.heading)}
                    />
                    <span className="slider"></span>
                  </label>
                </td>
              </tr>

              {expandedHeaders[section.heading] &&
                section.items.map((item, itemIndex) => (
                  <React.Fragment key={itemIndex}>
                    {/* Item Header */}
                    <tr>
                      <td
                        className="menu-item"
                        onClick={() => handleItemClick(item.itemName)}
                        style={{ color: "#1045be" }}
                      >
                        {item.itemName}
                        <span className="dropdown-arrow">
                          <Icon icon={expandedItems[item.itemName] ? arrowUp : arrowDown} />
                        </span>
                      </td>
                      <td>
                        <label className="switch">
                          <input
                            type="checkbox"
                            checked={item.PagePermission === 'enable'}
                            onChange={() => handleToggleChange(item.itemName)}
                          />
                          <span className="slider"></span>
                        </label>
                      </td>
                    </tr>

                    {expandedItems[item.itemName] && (
                      <tr className="submenu-row">
                        <td colSpan="2">
                          <table className="submenu-table">
                            <thead>
                              <tr>
                                <th>Sequence</th>
                                <th>Field Name</th>
                                <th>
                                  Read & Write
                                  <input
                                    type="checkbox"
                                    checked={item.PageControls.every(control => control.visibility === "ReadWrite")}

                                    onChange={() => handleSelectAll(section.heading, item.itemName, item.PageControls, "ReadWrite")}
                                  />
                                </th>
                                <th>
                                  Mask
                                  <input
                                    type="checkbox"
                                    checked={item.PageControls.every(control => control.visibility === "Mask")}

                                    onChange={() => handleSelectAll(section.heading, item.itemName, item.PageControls, "Mask")}
                                  />
                                </th>
                                <th>
                                  Read Only
                                  <input
                                    type="checkbox"
                                    checked={item.PageControls.every(control => control.visibility === "ReadOnly")}

                                    onChange={() => handleSelectAll(section.heading, item.itemName, item.PageControls, "ReadOnly")}
                                  />
                                </th>
                                <th>
                                  Don't Show
                                  <input
                                    type="checkbox"
                                    checked={item.PageControls.every(control => control.visibility === "Hidden")}

                                    onChange={() => handleSelectAll(section.heading, item.itemName, item.PageControls, "Hidden")}
                                  />
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {/* Group controls by position */}
                              {(() => {
                                const { topControls, middleControls, bottomControls } = groupControlsByPosition(item.PageControls);

                                return (
                                  <>
                                    {/* Render Top Controls */}
                                    {topControls.map((field) => (
                                      <tr key={field.key}>
                                        <td>
                                          <input
                                            type="number"
                                            placeholder="Enter Sequence"
                                            value={field.priority || ""}
                                            onChange={(e) => handleSequenceChange(section.PageId, item.itemName, field.key, e.target.value)}
                                            min="1"
                                            style={{ width: '50px' }}
                                          />
                                        </td>
                                        <td>{field.label}</td>
                                        <td>
                                          <input
                                            type="checkbox"
                                            checked={field.visibility === "ReadWrite"}
                                            onChange={() => toggleVisibility(section.PageId, item.itemName, field.key, "ReadWrite")}
                                          />
                                        </td>
                                        <td>
                                          <input
                                            type="checkbox"
                                            checked={field.visibility === "Mask"}
                                            onChange={() => toggleVisibility(section.PageId, item.itemName, field.key, "Mask")}
                                          />
                                        </td>
                                        <td>
                                          <input
                                            type="checkbox"
                                            checked={field.visibility === "ReadOnly"}
                                            onChange={() => toggleVisibility(section.PageId, item.itemName, field.key, "ReadOnly")}
                                          />
                                        </td>
                                        <td>
                                          <input
                                            type="checkbox"
                                            checked={field.visibility === "Hidden"}
                                            onChange={() => toggleVisibility(section.PageId, item.itemName, field.key, "Hidden")}
                                          />
                                        </td>
                                      </tr>
                                    ))}

                                    {/* Middle Section Heading */}
                                    {middleControls.length > 0 && (
                                      <tr>
                                        <td colSpan="6" style={{ fontWeight: "bold", textAlign: "center" }}>Middle Fields</td>
                                      </tr>
                                    )}

                                    {/* Render Middle Controls */}
                                    {middleControls.map((field) => (
                                      <tr key={field.key}>
                                        <td>
                                          <input
                                            type="number"
                                            placeholder="Enter Sequence"
                                            value={field.priority || ""}
                                            onChange={(e) => handleSequenceChange(section.PageId, item.itemName, field.key, e.target.value)}
                                            min="1"
                                            style={{ width: '50px' }}
                                          />
                                        </td>
                                        <td>{field.label}</td>
                                        <td>
                                          <input
                                            type="checkbox"
                                            checked={field.visibility === "ReadWrite"}
                                            onChange={() => toggleVisibility(section.PageId, item.itemName, field.key, "ReadWrite")}
                                          />
                                        </td>
                                        <td>
                                          <input
                                            type="checkbox"
                                            checked={field.visibility === "Mask"}
                                            onChange={() => toggleVisibility(section.PageId, item.itemName, field.key, "Mask")}
                                          />
                                        </td>
                                        <td>
                                          <input
                                            type="checkbox"
                                            checked={field.visibility === "ReadOnly"}
                                            onChange={() => toggleVisibility(section.PageId, item.itemName, field.key, "ReadOnly")}
                                          />
                                        </td>
                                        <td>
                                          <input
                                            type="checkbox"
                                            checked={field.visibility === "Hidden"}
                                            onChange={() => toggleVisibility(section.PageId, item.itemName, field.key, "Hidden")}
                                          />
                                        </td>
                                      </tr>
                                    ))}

                                    {/* Bottom Section Heading */}
                                    {bottomControls.length > 0 && (
                                      <tr>
                                        <td colSpan="6" style={{ fontWeight: "bold", textAlign: "center" }}>Bottom Fields</td>
                                      </tr>
                                    )}

                                    {/* Render Bottom Controls */}
                                    {bottomControls.map((field) => (
                                      <tr key={field.key}>
                                        <td>
                                          <input
                                            type="number"
                                            placeholder="Enter Sequence"
                                            value={field.priority || ""}
                                            onChange={(e) => handleSequenceChange(section.PageId, item.itemName, field.key, e.target.value)}
                                            min="1"
                                            style={{ width: '50px' }}
                                          />
                                        </td>
                                        <td>{field.label}</td>
                                        <td>
                                          <input
                                            type="checkbox"
                                            checked={field.visibility === "ReadWrite"}
                                            onChange={() => toggleVisibility(section.PageId, item.itemName, field.key, "ReadWrite")}
                                          />
                                        </td>
                                        <td>
                                          <input
                                            type="checkbox"
                                            checked={field.visibility === "Mask"}
                                            onChange={() => toggleVisibility(section.PageId, item.itemName, field.key, "Mask")}
                                          />
                                        </td>
                                        <td>
                                          <input
                                            type="checkbox"
                                            checked={field.visibility === "ReadOnly"}
                                            onChange={() => toggleVisibility(section.PageId, item.itemName, field.key, "ReadOnly")}
                                          />
                                        </td>
                                        <td>
                                          <input
                                            type="checkbox"
                                            checked={field.visibility === "Hidden"}
                                            onChange={() => toggleVisibility(section.PageId, item.itemName, field.key, "Hidden")}
                                          />
                                        </td>
                                      </tr>
                                    ))}
                                  </>
                                );
                              })()}
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                ))}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default VerticalMenu;




// const VerticalMenu = ({
//   initialModulesData, // Updated to use initialModulesData
//   itemfields,
//   toggleVisibility,
//   handleHeaderToggleChange,
//   expandedHeaders,
//   checkedHeaders,
//   handleHeaderClick,
//   expandedItems,
//   checkedItems,
//   handleItemClick,
//   handleToggleChange,
//   fieldSequence,
//   handleSequenceChange,
// }) => {
//   return (
//     <div className="menu-table-container">
//       <table className="menu-table">
//         <thead>
//           <tr>
//             <th>Modules</th>
//             <th>Enable</th>
//           </tr>
//         </thead>

//         <tbody>
//           {initialModulesData.map((section) => (
//             <React.Fragment key={section.PageId}>
//               <tr
//                 onClick={() => handleHeaderClick(section.heading)}
//                 style={{ cursor: "pointer", backgroundColor: "#e0e0e0" }}
//               >
//                 <td style={{ color: "#1045be" }}>
//                   {section.PageDisplayName}
//                   <span className="dropdown-arrow">
//                     <Icon icon={expandedHeaders[section.heading] ? arrowUp : arrowDown} />
//                   </span>
//                 </td>
//                 <td>
//                   <label className="switch">
//                     <input
//                       type="checkbox"
//                       checked={checkedHeaders[section.heading] || false}
//                       onChange={() => handleHeaderToggleChange(section.heading)}
//                     />
//                     <span className="slider"></span>
//                   </label>
//                 </td>
//               </tr>

//               {expandedHeaders[section.heading] &&
//                 section.items.map((item, itemIndex) => (
//                   <React.Fragment key={itemIndex}>
//                     <tr>
//                       <td
//                         className="menu-item"
//                         onClick={() => handleItemClick(item.itemName)}
//                         style={{ color: "#1045be" }}
//                       >
//                         {item.itemName}
//                         <span className="dropdown-arrow">
//                           <Icon icon={expandedItems[item.itemName] ? arrowUp : arrowDown} />
//                         </span>
//                       </td>
//                       <td>
//                         <label className="switch">
//                           <input
//                             type="checkbox"
//                             checked={checkedItems[item.itemName] || false}
//                             onChange={() => handleToggleChange(item.itemName)}
//                           />
//                           <span className="slider"></span>
//                         </label>
//                       </td>
//                     </tr>

//                     {expandedItems[item.itemName] && (
//                       <tr className="submenu-row">
//                         <td colSpan="2">
//                           <table className="submenu-table">
//                             <thead>
//                               <tr>
//                                 <th>Sequence</th>
//                                 <th>Field Name</th>
//                                 <th>Read & Write</th>
//                                 <th>Mask</th>
//                                 <th>Read Only</th>
//                                 <th>Don't Show</th>
//                               </tr>
//                             </thead>
//                             <tbody>
//                               {(item.PageControls || []).map((field) => (
//                                 <tr key={field.key}>
//                                   <td>
//                                     <input
//                                       type="number"
//                                       placeholder="Enter Sequence"
//                                       value={field.priority || ""}
//                                       onChange={(e) => handleSequenceChange(section.PageId, item.itemName, field.key, e.target.value)}
//                                       min="1"
//                                       style={{ width: '50px' }}
//                                     />
//                                   </td>
//                                   <td>{field.label}</td>
//                                   <td>
//                                     <input
//                                       type="checkbox"
//                                       checked={field.visibility === "ReadWrite"}
//                                       onChange={() => toggleVisibility(section.PageId, item.itemName, field.key, "ReadWrite")}
//                                     />
//                                   </td>
//                                   <td>
//                                     <input
//                                       type="checkbox"
//                                       checked={field.visibility === "Mask"}
//                                       onChange={() => toggleVisibility(section.PageId, item.itemName, field.key, "Mask")}
//                                     />
//                                   </td>
//                                   <td>
//                                     <input
//                                       type="checkbox"
//                                       checked={field.visibility === "ReadOnly"}
//                                       onChange={() => toggleVisibility(section.PageId, item.itemName, field.key, "ReadOnly")}
//                                     />
//                                   </td>
//                                   <td>
//                                     <input
//                                       type="checkbox"
//                                       checked={field.visibility === "Hidden"}
//                                       onChange={() => toggleVisibility(section.PageId, item.itemName, field.key, "Hidden")}
//                                     />
//                                   </td>
//                                 </tr>
//                               ))}
//                             </tbody>
//                           </table>
//                         </td>
//                       </tr>
//                     )}
//                   </React.Fragment>
//                 ))}
//             </React.Fragment>
//           ))}
//         </tbody>
//       </table>
//     </div>
//   );
// };

// export default VerticalMenu;



// import React, { useEffect, useState } from "react";
// import { Icon } from "@iconify/react";
// import arrowDown from "@iconify/icons-eva/arrow-down-outline";
// import arrowUp from "@iconify/icons-eva/arrow-up-outline";
// import "./VerticalMenu.css";



// const VerticalMenu = ({
//   initialModulesData, // Updated to use initialModulesData
//   itemfields,
//   toggleVisibility,
//   handleHeaderToggleChange,
//   expandedHeaders,
//   checkedHeaders,
//   handleHeaderClick,
//   expandedItems,
//   checkedItems,
//   handleItemClick,
//   handleToggleChange,
//   fieldSequence,
//   handleSequenceChange,
// }) => {
//   return (
//     <div className="menu-table-container">
//       <table className="menu-table">
//         <thead>
//           <tr>
//             <th>Modules</th>
//             <th>Enable</th>
//           </tr>
//         </thead>

//         <tbody>
//           {initialModulesData.map((section) => (
//             <React.Fragment key={section.PageId}>
//               <tr
//                 onClick={() => handleHeaderClick(section.heading)}
//                 style={{ cursor: "pointer", backgroundColor: "#e0e0e0" }}
//               >
//                 <td style={{ color: "#1045be" }}>
//                   {section.PageDisplayName}
//                   <span className="dropdown-arrow">
//                     <Icon icon={expandedHeaders[section.heading] ? arrowUp : arrowDown} />
//                   </span>
//                 </td>
//                 <td>
//                   <label className="switch">
//                     <input
//                       type="checkbox"
//                       checked={checkedHeaders[section.heading] || false}
//                       onChange={() => handleHeaderToggleChange(section.heading)}
//                     />
//                     <span className="slider"></span>
//                   </label>
//                 </td>
//               </tr>

//               {expandedHeaders[section.heading] &&
//                 section.items.map((item, itemIndex) => (
//                   <React.Fragment key={itemIndex}>
//                     <tr>
//                       <td
//                         className="menu-item"
//                         onClick={() => handleItemClick(item.itemName)}
//                         style={{ color: "#1045be" }}
//                       >
//                         {item.itemName}
//                         <span className="dropdown-arrow">
//                           <Icon icon={expandedItems[item.itemName] ? arrowUp : arrowDown} />
//                         </span>
//                       </td>
//                       <td>
//                         <label className="switch">
//                           <input
//                             type="checkbox"
//                             checked={checkedItems[item.itemName] || false}
//                             onChange={() => handleToggleChange(item.itemName)}
//                           />
//                           <span className="slider"></span>
//                         </label>
//                       </td>
//                     </tr>

//                     {expandedItems[item.itemName] && (
//                       <tr className="submenu-row">
//                         <td colSpan="2">
//                           <table className="submenu-table">
//                             <thead>
//                               <tr>
//                                 <th>Sequence</th>
//                                 <th>Field Name</th>
//                                 <th>Read & Write</th>
//                                 <th>Mask</th>
//                                 <th>Read Only</th>
//                                 <th>Don't Show</th>
//                               </tr>
//                             </thead>
//                             <tbody>
//                               {(item.PageControls || []).map((field) => (
//                                 <tr key={field.key}>
//                                   <td>
//                                     <input
//                                       type="number"
//                                       placeholder="Enter Sequence"
//                                       value={fieldSequence[field.key] || ""}
//                                       onChange={(e) => handleSequenceChange(page.PageId, field.key, e.target.value)}
//                                       min="1"
//                                       style={{ width: '50px' }}
//                                     />
//                                   </td>
//                                   <td>{field.label}</td>
//                                   <td>
//                                     <input
//                                       type="checkbox"
//                                       checked={field.visibility === "ReadWrite"}
//                                       onChange={() => toggleVisibility(page.PageId, field.key, "ReadWrite")}
//                                     />
//                                   </td>
//                                   <td>
//                                     <input
//                                       type="checkbox"
//                                       checked={field.visibility === "Mask"}
//                                       onChange={() => toggleVisibility(page.PageId, field.key, "Mask")}
//                                     />
//                                   </td>
//                                   <td>
//                                     <input
//                                       type="checkbox"
//                                       checked={field.visibility === "ReadOnly"}
//                                       onChange={() => toggleVisibility(page.PageId, field.key, "ReadOnly")}
//                                     />
//                                   </td>
//                                   <td>
//                                     <input
//                                       type="checkbox"
//                                       checked={field.visibility === "Hidden"}
//                                       onChange={() => toggleVisibility(page.PageId, field.key, "Hidden")}
//                                     />
//                                   </td>
//                                 </tr>
//                               ))}
//                             </tbody>
//                           </table>
//                         </td>
//                       </tr>
//                     )}
//                   </React.Fragment>
//                 ))}
//             </React.Fragment>
//           ))}
//         </tbody>
//       </table>
//     </div>
//   );
// };

// export default VerticalMenu;





// const VerticalMenu = ({ menuData, itemfields, toggleVisibility,
//   handleHeaderToggleChange, expandedHeaders, checkedHeaders,
//   handleHeaderClick, expandedItems, checkedItems, handleItemClick,
//   handleToggleChange, fieldSequence, handleSequenceChange, initialModulesData }) => {

//   return (
//     <div className="menu-table-container">
//       <table className="menu-table">
//         <thead>
//           <tr>
//             <th>Modules</th>
//             <th>Enable</th>
//           </tr>
//         </thead>

//         <tbody>
//           {menuData.map((section, sectionIndex) => (
//             <React.Fragment key={sectionIndex}>
//               <tr
//                 onClick={() => handleHeaderClick(section.heading)}
//                 style={{ cursor: "pointer", backgroundColor: "#e0e0e0" }}
//               >
//                 <td className="heading-row" style={{ color: "#1045be" }}>
//                   {section.heading}
//                   <span className="dropdown-arrow">
//                     <Icon icon={expandedHeaders[section.heading] ? arrowUp : arrowDown} />
//                   </span>
//                 </td>
//                 <td>
//                   <label
//                     className="switch"
//                     onClick={(event) => event.stopPropagation()}
//                   >
//                     <input
//                       type="checkbox"
//                       checked={checkedHeaders[section.heading] || false}
//                       onChange={() => handleHeaderToggleChange(section.heading)}
//                     />
//                     <span className="slider"></span>
//                   </label>
//                 </td>
//               </tr>

//               {expandedHeaders[section.heading] &&
//                 section.items.map((item) => (
//                   <React.Fragment key={item}>
//                     <tr>
//                       <td
//                         className="menu-item"
//                         // onClick={() => handleItemClick(item)}
//                         onClick={() => {
//                           // Only expand if the switch is off
//                           if (!checkedItems[item]) handleItemClick(item);
//                         }}
//                         style={{ color: "#1045be" }}
//                       >
//                         {item}{" "}
//                         <span className="dropdown-arrow">
//                           <Icon icon={expandedItems[item] ? arrowUp : arrowDown} />
//                         </span>
//                       </td>
//                       <td>
//                         <label className="switch">
//                           <input
//                             type="checkbox"
//                             checked={checkedItems[item] || false}
//                             onChange={() => handleToggleChange(item)}
//                           />
//                           <span className="slider"></span>
//                         </label>
//                       </td>
//                     </tr>
//                     {expandedItems[item] && (
//                       <tr className="submenu-row">
//                         <td colSpan="2">
//                           <table className="submenu-table">
//                             <thead>
//                               <tr>
//                                 <th>Sequence</th> {/* New column for sequence */}
//                                 <th>Field Name</th>
//                                 <th>Read & Write</th>
//                                 <th>Mask</th>
//                                 <th>Read Only</th>
//                                 <th>Don't Show</th>
//                               </tr>
//                             </thead>
//                             <tbody>
//                               {/* {itemfields */}
//                               {itemfields
//                                 .slice() // Create a shallow copy to avoid mutating the original array
//                                 .sort((a, b) => a.key.localeCompare(b.key)) // Sort alphabetically by `fieldName`

//                                 .map((field) => (
//                                   <tr key={field.key}>
//                                     {/* Sequence input */}
//                                     <td>
//                                       <input
//                                         style={{ width: '15%' }}
//                                         type="number"
//                                         placeholder="Enter Sequence"
//                                         className="sequence-input"
//                                         value={fieldSequence[field.key] || ""}
//                                         onChange={(e) => handleSequenceChange(field.key, e.target.value)}
//                                         min="1"
//                                       />
//                                     </td>

//                                     {/* Field Name */}
//                                     <td>{field.label}</td>

//                                     {/* Checkboxes for permissions */}
//                                     <td>
//                                       <input
//                                         type="checkbox"
//                                         checked={field.visibility === "ReadWrite"}
//                                         onChange={() => toggleVisibility(field.key, "ReadWrite")}
//                                       />
//                                       {/* Read & Write */}
//                                     </td>
//                                     <td>
//                                       <input
//                                         type="checkbox"
//                                         checked={field.visibility === "Mask"}
//                                         onChange={() => toggleVisibility(field.key, "Mask")}
//                                       />
//                                       {/* Mask */}
//                                     </td>
//                                     <td>
//                                       <input
//                                         type="checkbox"
//                                         checked={field.visibility === "ReadOnly"}
//                                         onChange={() => toggleVisibility(field.key, "ReadOnly")}
//                                       />
//                                       {/* Read Only */}
//                                     </td>
//                                     <td>
//                                       <input
//                                         type="checkbox"
//                                         checked={field.visibility === "Hidden"}
//                                         onChange={() => toggleVisibility(field.key, "Hidden")}
//                                       />
//                                       {/* Don't Show */}
//                                     </td>

//                                   </tr>
//                                 ))}
//                             </tbody>
//                           </table>
//                         </td>
//                       </tr>
//                     )}
//                   </React.Fragment>
//                 ))}
//             </React.Fragment>
//           ))}
//         </tbody>
//       </table>
//     </div>
//   );
// };

// export default VerticalMenu;
