export const allDevicesList = [
  "RFID DEVICES",
  "HANDHLED RFID READER",
  "FIXED RFID READER",
  "RFID BILLING BOX",
  "RFID PRINTER",
  "BARCODE PRINTER",
  "BILLING PRINTER",
  "BARCODE SCANNER",
  "HANDHELD POS MACHINE",
  "EAS MACHINE",
  "RFID HARD TAGS",
  "RFID SOFT TAGS",
];
