import { ADMIN_LOGGED_IN } from "../ActionTypes";
import { ADMIN_LOGGED_OUT } from "../ActionTypes";
import { SET_PERMISSIONS } from "../ActionTypes";

const initialState = {
  Modules: [],
  permissionsLoaded: false,
  // other initial state properties
};

export const reducer1 = (state = initialState, action) => {
  switch (action.type) {
    case ADMIN_LOGGED_IN: {
      return action.payload;
    }
    case ADMIN_LOGGED_OUT: {
      return {}; // Reset state to an empty array
    }

    case SET_PERMISSIONS:
      return {
        ...state,
        Modules: action.payload, // Storing permissions from the API response
        permissionsLoaded: true,
      };


    default:
      return state;
  }
};
