import React, { useEffect, useState } from "react";
import AdminHeading from "../Heading/AdminHeading";
import { BiAlarm } from "react-icons/bi";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import "./AdminSetting.css";

import { useSelector } from "react-redux";

import { a113, a95, a98 } from "../../../Api/RootApiPath";
import AdminTopNavbar from "../Heading/AdminTopNavbar";
import { CiSearch } from "react-icons/ci";
import { RiMenuSearchLine, RiUserSettingsLine } from "react-icons/ri";
import { FaRegUserCircle, FaRupeeSign } from "react-icons/fa";
import { CgPlayListSearch } from "react-icons/cg";
import { BsClock } from "react-icons/bs";
import { MdCurrencyRupee } from "react-icons/md";

export default function AdminHome() {
  const [currentStep, setCurrentStep] = useState(1);
  const [allCompaniesList, setAllCompaniesList] = useState([]);
  const [allBranchesList, setAllBranchesList] = useState([]);
  const [allBanksList, setAllBanksList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [firstTimeSetup, setFirstTimeSetup] = useState(false);

  const [showError, setShowError] = useState(false);
  const [messageType, setMessageType] = useState("");
  const [messageToShow, setMessageToShow] = useState("");

  const [activeMenu, setActiveMenu] = useState(null);

  const navigate = useNavigate();

  const data = [
    {
      display: "Vendor Tounche",
      module: "Stock",
      sharedTo: "All Profiles",
      modified: "26 Oct 2024, Sat",
    },
    {
      display: "Customer Tounche",
      module: "Sale",
      sharedTo: "All Profiles",
      modified: "26 Oct 2024, Sat",
    },
    {
      display: "Diamond Attributes",
      module: "Order List",
      sharedTo: "All Profiles",
      modified: "26 Oct 2024, Sat",
    },
    {
      display: "Pair Customer Vendor",
      module: "Purchase",
      sharedTo: "All Profiles",
      modified: "26 Oct 2024, Sat",
    },
    {
      display: "Customer Slab",
      module: "Customer Ledger",
      sharedTo: "All Profiles",
      modified: "26 Oct 2024, Sat",
    },
    {
      display: "Customer Rate Of Interest",
      module: "Supplier Ledger",
      sharedTo: "All Profiles",
      modified: "26 Oct 2024, Sat",
    },
    {
      display: "Customer Credit Period",
      module: "Old Metal",
      sharedTo: "All Profiles",
      modified: "26 Oct 2024, Sat",
    },
  ];

  const toggleMenu = (menu) => {
    setActiveMenu(activeMenu === menu ? null : menu);
  };

  useEffect(() => {
    setTimeout(() => {
      setShowError(false);
    }, 2000);
  }, [showError]);

  const allStates = useSelector((state) => state);
  const adminLoggedIn = allStates.reducer1;
  //   let Entryby_Staff_id = parseInt(adminLoggedIn);
  const clientCode = adminLoggedIn.ClientCode;

  const fetchAllCompanies = async () => {
    const formData = {
      ClientCode: clientCode,
    };
    const response = await fetch(a95, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    });
    const data = await response.json();
    console.log(data, "data,");
    try {
      if (data.length > 0) {
        setAllCompaniesList(data);
        fetchAllBranches();
      } else {
        setCurrentStep(1);
        setLoading(false);
        // alert("Please Add Company First");
        setMessageType("warning");
        setMessageToShow("Please Add Company First");
        setShowError(true);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchAllCompanies();
  }, []);

  const fetchAllBranches = async () => {
    const formData = {
      ClientCode: clientCode,
    };
    const response = await fetch(a98, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    });
    const data = await response.json();
    console.log(data, "data,");
    try {
      if (data.length > 0) {
        setAllBranchesList(data);
        fetchAllBanks();
      } else {
        setCurrentStep(2);
        setLoading(false);
        setMessageType("warning");
        setMessageToShow("Please Add Branch");
        setShowError(true);
      }
    } catch (error) {
      console.log(error);
    }
  };
  // useEffect(() => {
  //   fetchAllBranches();
  // }, []);

  const fetchAllBanks = async () => {
    const formData = {
      ClientCode: clientCode,
    };
    const response = await fetch(a113, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    });
    const data = await response.json();
    console.log(data, "data,");
    try {
      if (data.length > 0) {
        setAllBanksList(data);
        setLoading(false);
        setCurrentStep(0);
      } else {
        setCurrentStep(3);
        setLoading(false);
        setMessageType("warning");
        setMessageToShow("Please Add Bank");
        setShowError(true);
      }
    } catch (error) {
      console.log(error);
    }
  };
  // useEffect(() => {
  //   fetchAllBanks();
  // }, []);
  // useEffect(() => {
  //   if (allCompaniesList.length < 1) {
  //     setCurrentStep(1);
  //   } else if (allBranchesList.length < 1) {
  //     setCurrentStep(2);
  //   } else if (allBranchesList.length < 1) {
  //     setCurrentStep(3);
  //   } else {
  //     setCurrentStep(0);
  //   }
  // }, []);

  const handleMessageToShow = (e) => {
    setMessageToShow(e);
  };
  const handleMessageType = (e) => {
    setMessageType(e);
    setShowError(true);
    setCurrentStep(0);
    fetchAllCompanies();
  };

  return (
    <div>
      <AdminTopNavbar />
    
      <div className="main-container">
        <div className="side-menu">
          <div className="left-tab-bar">
            <NavLink to="#" activeClassName="active-link">
              <RiUserSettingsLine className="icon" />
            </NavLink>
            <NavLink to="#" activeClassName="active-link">
              <FaRegUserCircle className="icon" />
            </NavLink>
            <NavLink to="#" activeClassName="active-link">
              <RiMenuSearchLine className="icon" />
            </NavLink>
            <NavLink to="#" activeClassName="active-link">
              <MdCurrencyRupee className="icon" />
            </NavLink>
            <NavLink to="#" activeClassName="active-link">
              <RiUserSettingsLine className="icon" />
            </NavLink>
            <NavLink to="#" activeClassName="active-link">
              <BsClock className="icon" />
            </NavLink>
            <NavLink to="#" activeClassName="active-link">
              <BsClock className="icon" />
            </NavLink>
            <NavLink to="#" activeClassName="active-link">
              <BsClock className="icon" />
            </NavLink>
            <NavLink to="#" activeClassName="active-link">
              <BsClock className="icon" />
            </NavLink>
            <NavLink to="#" activeClassName="active-link">
              <BsClock className="icon" />
            </NavLink>
            <NavLink to="#" activeClassName="active-link">
              <BsClock className="icon" />
            </NavLink>
            <NavLink to="#" activeClassName="active-link">
              <BsClock className="icon" />
            </NavLink>
          </div>

          <div className="option-menu">
            <div className="search-bar">
              <CiSearch />
              <input type="text" placeholder="Search" />
            </div>
            <ul className="menu-list">
              <li>
                <div
                  className="menu-item"
                  onClick={() => navigate("/RollManagement")}
                >
                  {/* <span>{activeMenu === "Roll Management" ? "▼" : "▶"}</span>{" "} */}
                  Role Management
                </div>
                {
                  activeMenu === "Roll Management"
                  // &&
                  // (
                  //   <ul className="submenu">
                  //     <li>Option 1</li>
                  //     <li>Option 2</li>
                  //     <li>Option 3</li>
                  //   </ul>
                  // )
                }
              </li>
              <li>
                <div
                  className="menu-item"
                  onClick={() => toggleMenu("User Masterrr")}
                >
                  <span>{activeMenu === "User Masterrr" ? "▼" : "▶"}</span> User
                  Master
                </div>
                {activeMenu === "User Masterrr" && (
                  <ul className="submenu">
                    <li>
                      <NavLink to="/UserMaster">User Master</NavLink>
                    </li>
                  </ul>
                )}
              </li>
              <li>
                <div
                  className="menu-item"
                  onClick={() => toggleMenu("User Roles")}
                >
                  <span>{activeMenu === "User Roles" ? "▼" : "▶"}</span> User
                  Role
                </div>
                {activeMenu === "User Roles" && (
                  <ul className="submenu">
                    <li>
                      <NavLink to="/user-roles">User Roles</NavLink>
                    </li>
                  </ul>
                )}
              </li>
              <li>
                <div
                  className="menu-item"
                  onClick={() => toggleMenu("Products Master")}
                >
                  <span>{activeMenu === "Products Master" ? "▼" : "▶"}</span>{" "}
                  Products Masterrr
                </div>
                {activeMenu === "Products Master" && (
                  <ul className="submenu">
                    <li>
                      <NavLink to="/ProductMaster">Product Master</NavLink>
                    </li>
                  </ul>
                )}
              </li>
              <li>
                <div
                  className="menu-item"
                  onClick={() => toggleMenu("Trading")}
                >
                  <span>{activeMenu === "Trading" ? "▼" : "▶"}</span> Trading
                </div>
                {activeMenu === "Trading" && (
                  <ul className="submenu">
                   <li>
                      <NavLink to="/Trading">Trading</NavLink>
                    </li>
                  </ul>
                )}
              </li>
              <li>
                <div
                  className="menu-item"
                  onClick={() => toggleMenu("Reports")}
                >
                  <span>{activeMenu === "Reports" ? "▼" : "▶"}</span> Reports
                </div>
                {activeMenu === "Reports" && (
                  <ul className="submenu">
                      <li>
                      <NavLink to="/Reports">Reports</NavLink>
                    </li>
                  </ul>
                )}
              </li>
              <li>
                <div
                  className="menu-item"
                  onClick={() => toggleMenu("Settings")}
                >
                  <span>{activeMenu === "Settings" ? "▼" : "▶"}</span> Settings
                </div>
                {activeMenu === "Settings" && (
                  <ul className="submenu">
                    <li>
                      <NavLink to="/AdminSetting">Admin Setting</NavLink>{" "}
                    </li>
                  </ul>
                )}
              </li>
            </ul>
          </div>
        </div>
        <div className="right-box">
          <div className="right-box-header">
            <h1
              style={{
                fontSize: "15px",
                color: "#000",
                fontWeight: "bold",
                borderBottom: "2px solid #00B4B8", // Border color and thickness
                display: "inline-block", // Ensure the border is only as wide as the text
                paddingBottom: "4px", // Add some space between text and border
              }}
            >
              Reports
            </h1>{" "}
          </div>
          <div className="right-body-head">
            <div className="search-bar">
              <CiSearch />
              <input type="text" placeholder="Search" />
            </div>
            <div className="button-group">
              <button className="button organize-button">
                Organize Module
              </button>
              <button className="button new-button">New Module</button>
            </div>
          </div>
          <div className="table-container">
            <table>
              <thead>
                <tr>
                  <th>Display in tab as</th>
                  <th>Module Name</th>
                  <th>Shared To</th>
                  <th>Last Modified</th>
                </tr>
              </thead>
              <tbody>
                {data.map((row, index) => (
                  <tr key={index}>
                    <td>
                      <a href="#">{row.display}</a>
                    </td>
                    <td>{row.module}</td>
                    <td>{row.sharedTo}</td>
                    <td>{row.modified}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {/* </div> */}
    </div>
  );
}
