export const popularBanksIndia = [
  "State Bank of India",
  "HDFC Bank",
  "ICICI Bank",
  "Axis Bank",
  "Kotak Mahindra Bank",
  "Punjab National Bank",
  "Bank of Baroda",
  "Canara Bank",
  "IndusInd Bank",
  "Union Bank of India",
  "Bank of India",
  "IDFC First Bank",
  "Central Bank of India",
  "Indian Bank",
  "Yes Bank",
  "UCO Bank",
  "Bank of Maharashtra",
  "Punjab & Sind Bank",
  "Indian Overseas Bank",
  "Jammu & Kashmir Bank",
  "Federal Bank",
  "South Indian Bank",
  "Karur Vysya Bank",
  "City Union Bank",
  "RBL Bank",
  "Bandhan Bank",
  "DCB Bank",
  "Tamilnad Mercantile Bank",
  "Suryoday Small Finance Bank",
  "Ujjivan Small Finance Bank",
  "Equitas Small Finance Bank",
  "AU Small Finance Bank",
  "ESAF Small Finance Bank",
  "Fincare Small Finance Bank",
  "Jana Small Finance Bank",
  "North East Small Finance Bank",
  "Shivalik Small Finance Bank",
  "Unity Small Finance Bank",
  "Utkarsh Small Finance Bank"
];
export const popularBanksUS = [
  "JPMorgan Chase",
  "Bank of America",
  "Wells Fargo",
  "Citibank",
  "U.S. Bank",
  "PNC Bank",
  "Truist Bank",
  "Goldman Sachs",
  "TD Bank",
  "Capital One",
  "HSBC USA",
  "Charles Schwab",
  "Fifth Third Bank",
  "Ally Bank",
  "American Express Bank",
];
